import { TextBlock } from '../Atoms/Text';
import { Icon } from '../Atoms/Icon';
import { useState } from 'react';
import Link from '@mui/material/Link';
import { DEFAULT_ADVANCE_BUTTON_STYLE, RAISED_DIALOG } from '../../utils/styleHelpers';
import { useSharedUser } from '../../store/User';

export const TosModal = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const { updateUser } = useSharedUser();

  const handleExpand = () => setIsExpanded(!isExpanded);
  const handleAccept = () => {
    setIsAccepted(true);
    updateUser();
  };

  return (
    <div className="h-screen w-11/12 page-layout m-auto basis-full">
      <div className="flex justify-center h-full">
        <div className="grid place-items-center">
          <div className={RAISED_DIALOG}>
            <div className="p-1 w-[39rem]">
              <div className="mb-3">
                <TextBlock
                  className="font-medium"
                  size="lg"
                  weight="bold">
                  Terms of Service
                </TextBlock>
              </div>
              <div className="mb-4">
                <TextBlock size="sm14">To continue, please accept our terms and conditions of service.</TextBlock>
              </div>
              <div className="border py-4 pl-4 pr-9 relative max-h-[29rem] overflow-y-scroll">
                <TextBlock
                  className="text-justify mb-5"
                  size="sm14">
                  These terms of use (the “Terms”) are entered into between you and UPSTACK HOLDCO INC. (“UPSTACK”) and
                  represent a legal agreement between you and UPSTACK. These Terms, together with any documents they
                  expressly incorporate by reference, including the Privacy Policy, govern your and your organization’s
                  access to and use of all of the following (collectively, the “Services”):
                </TextBlock>
                <TextBlock
                  className="text-justify mb-5"
                  size="sm14">
                  (1) the application [https://portal.upstack.io] (the “Site”); (2) any services or applications offered
                  by UPSTACK (but not by third parties) in connection with the Site; and (3) any information, data,
                  software, interfaces, code, widgets, tools or other materials contained in or accessible by means of
                  the Site (the “UPSTACK Information”).
                </TextBlock>
                {isExpanded && (
                  <div>
                    <TextBlock
                      className="text-justify mb-5"
                      weight="medium">
                      By accessing or using any of the Services, you acknowledge that you have read, understood, and
                      agree to be bound by and comply with these Terms without any modification or reservation
                      whatsoever. If you do not wish to be bound by these Terms, your only option is not to use or
                      access the Site. If you are accessing these Services on behalf of a company, a vendor or other
                      commercial enterprise (“Commercial Principal”), you are agreeing on behalf of the Commercial
                      Principal that the Commercial Principal will be bound by these terms and are certifying that you
                      have the authority to bind the Commercial Principal. If the Commercial Principal does not agree to
                      all or any of these Terms, or if you do not have the authority to bind the Commercial Principal to
                      these Terms, do not select the “Accept” box when prompted.
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14">
                      If you are an employee of or are accessing the Services or the Site on behalf of a Commercial
                      Principal, please note that UPSTACK may have entered into a separate service and/or subscription
                      agreement with your Commercial Principal (“Service Agreement”). If UPSTACK has entered into a
                      Service Agreement with your Commercial Principal, you may be subject to additional terms,
                      conditions, obligations, representations and/or warranties made under the Service Agreement.
                      Please refer to the Service Agreement entered between the Commercial Principal and UPSTACK to get
                      an accurate understanding of your rights, obligations, and terms of use before using this Site
                      and/or Services.
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14">
                      UPSTACK reserves the right, in its sole discretion, without any obligation and without any notice
                      requirement, to modify or revise these Terms. You are responsible for checking these Terms from
                      time to time. Your continued use of the Services constitutes your acceptance of the new Terms.
                      These Terms control the relationship between UPSTACK and you. They do not create any third party
                      beneficiary rights. UPSTACK also reserves the right to suspend and/or deny access to the Services
                      for scheduled or unscheduled maintenance, upgrades, improvements, corrections or otherwise.
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14"
                      weight="medium">
                      1. USE OF SERVICES
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14">
                      You acknowledge that UPSTACK is not a colocation or network services provider and does not
                      represent or speak on behalf of any colocation or network services provider. UPSTACK is a data
                      aggregator, and the data provided is for informational purposes only. The Services are in no way a
                      substitute for your own due diligence, and UPSTACK does not guarantee the accuracy or correctness
                      of the information provided.
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14">
                      In order to access or use the Services, you may be required to provide current and accurate
                      identification, a username, password and other information. When registering to access or use the
                      Services, you warrant that you are an authorized representative of your organization, and have
                      authority to make decisions and legally bind your organization to adhere to these Terms. You also
                      agree to correctly and truthfully identify yourself and/or your organization (e.g. broker,
                      consultant, buyer, vendor) when registering to use the Services.
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14">
                      You are responsible for maintaining the accuracy and completeness of this information and for
                      maintaining the confidentiality of your login information required to use the Services. You will
                      be fully responsible for all activities that occur under your account with UPSTACK, including
                      activities of others to whom you have provided your username or password. Please notify UPSTACK
                      immediately of any unauthorized use of your account or any other breach of security.
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14">
                      Certain Services are provided to you free-of-charge. Other Services require payment before you can
                      access them. The type of Services available to you depends on the type of service plan that you or
                      your Commercial Principal have selected. Therefore, certain functionalities of the Site and/or
                      certain Services may not be accessible by or available to you. These additional functions and
                      services may be available to you at an additional cost.
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14"
                      weight="medium">
                      2. AGE AND JURISDICTION
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14">
                      You must be at least 18 years of age to use the Services, with or without registering. This Site
                      and Services are offered and available to users who are eighteen (18) years of age or older and
                      reside in the United States or any of its territories or possessions only. By using this Site, you
                      represent and warrant that you are of legal age to form a binding contract with UPSTACK and meet
                      all of the foregoing eligibility requirements. If you do not meet all of these requirements, you
                      must not access or use the Site. If you are eighteen (18) years of age, but reside in a
                      jurisdiction outside the United States and its territories and possessions, you acknowledge and
                      agree that you use the Site at your own risk, and that you agree to be bound by these Terms,
                      regardless of any conflicting provisions applicable in your country of residence.
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14"
                      weight="medium">
                      3. THIRD PARTY SERVICES
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14">
                      The Services may provide you with an opportunity to engage with third party services, provide you
                      with information pertaining to third party services or provide you with links to third party
                      services (“Third Party Services”) offered or made available by third party suppliers (“Third Party
                      Organizations”). Such Third Party Organizations are not related to, associated with or affiliated
                      with UPSTACK, and UPSTACK has no involvement in providing the Third Party Services to you. UPSTACK
                      merely acts as a conduit to allow you to identify such third party suppliers and service
                      providers.
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14">
                      UPSTACK is neither responsible nor liable for any activities you engage in with or any
                      communications you have with such Third Party Organizations, even if you identify such Third Party
                      Organizations on or through the use of the Services or communicate with them through the use of
                      the Services. These Terms do not apply to any Third Party Services and these Terms do not govern
                      the practices of such Third Party Organization. When utilizing Third Party Services, you should
                      read the relevant terms of use regarding use of such Third Party Organization websites or services
                      including any applicable end user license agreements.
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14">
                      No selections you make on the Site (e.g., colocation capacity or location, network build services)
                      are binding on any Third Party Organization unless you enter into a separate agreement with the
                      Third Party Organization for your desired Third Party Services. Your use of Third Party Services
                      is subject to an independent agreement with the applicable Third Party Organization providing such
                      Third Party Services and is not governed by these Terms. Please read carefully all agreements,
                      order forms and commitments you enter into with Third Party Organization for Third Party Services,
                      and you agree that all such agreements, order forms and commitments are your sole responsibility.
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14">
                      Please be aware that Third Party Organizations provide their information to UPSTACK voluntarily.
                      Certain Third Party Organizations who are colocation or network service providers may upload
                      documents and information dealing with their pricing and service levels. UPSTACK has no control
                      over such information and resources provided and UPSTACK is not responsible for or provide any
                      guarantee, warranty or recommendation to such Third Party Services and/or Third Party
                      Organization. UPSTACK does not verify whether any such information provided on the Site about the
                      Third Party Organization or Third Party Services is correct or up to date. You acknowledge that
                      the Site may contain out of date or incorrect information about the Third Party Organization or
                      the Third Party Services. It is your sole responsibility to obtain updated, complete and accurate
                      information, including information about service levels, final pricing and quotes, about the Third
                      Party Organization and the Third Party Services before you engage any such Third Party
                      Organization or enter into any contract with them. UPSTACK will not be responsible or liable,
                      directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection
                      with use of or reliance on any content, information, events, goods or services available by or
                      through such Third Party Organization.
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14"
                      weight="medium">
                      4. LICENSE
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14">
                      If you are eligible to use the Services, UPSTACK grants you a non-exclusive, non-transferable,
                      nonsublicensable, revocable license to access and use the Services solely under the terms and
                      conditions provided hereunder. Any use of the Services other than as authorized by these Terms is
                      strictly prohibited and will terminate the license granted herein. The technology and software
                      underlying the Services is the property of UPSTACK and/or its licensors (the “Software”). UPSTACK
                      reserves all right, title and interest in and to the Software, except for the limited rights
                      expressly granted in these Terms.
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14"
                      weight="medium">
                      5. PROPRIETARY RIGHTS
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14">
                      UPSTACK and/or its licensors own the Site and the Services, including all software and other
                      technology provided or employed by UPSTACK in connection with the Services, and the contents,
                      design, layout, functions, appearance and other intellectual property comprising the Services,
                      including all copyrights, trademarks, service marks, trade secrets, patents and other intellectual
                      property rights inherent therein or appurtenant thereto. UPSTACK does not grant any licenses to
                      any copyrights, patents, trademarks, trade secrets or other intellectual property rights other
                      than the limited rights to use the Services, as set forth herein and subject to these Terms.
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14">
                      The Site may contain also contain intellectual property of Third Party Organizations, including
                      service marks and trademarks. Your use of this Site or the Services does not give you any right or
                      license to use such intellectual property, without the prior written permission of the
                      corresponding intellectual property owner.
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14">
                      All rights not expressly granted herein are reserved by UPSTACK. Your use of this Site does not
                      grant you ownership rights of any kind in the Site or Services.
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14"
                      weight="medium">
                      6. USER CONDUCT
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14">
                      In using the Services, you agree (i) not to send to UPSTACK or upload onto or through the Site any
                      information unless you have consent to do so; and (ii) that you will not:
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5 ml-5"
                      size="sm14">
                      (a) violate any applicable international, country, province, federal or state laws, regulations or
                      rules (collectively, “Laws”) as a result of your use of the Site;
                      <br />
                      (b) make any information available to UPSTACK if doing so would violate any applicable Laws,
                      industry or professional codes or standards, contractual or fiduciary obligations, confidentiality
                      obligations, or employer policies or other requirements to which you are bound;
                      <br />
                      (c) transmit any information, data, images, or other materials or post any content on the Site
                      that contain vulgar, profane, abusive or hateful language, epithets or slurs, text or
                      illustrations in poor taste, inflammatory attacks of a personal, racial or religious nature, or
                      expressions of bigotry, racism, discrimination or hate, or are unlawful, harmful, threatening,
                      harassing, libelous, defamatory, vulgar, obscene or otherwise objectionable or that may invade
                      another’s right of privacy or infringe any intellectual property right, including patent,
                      trademark, service mark, trade secret, copyright or other proprietary rights of any third party;
                      <br />
                      (d) provide any false personal information on UPSTACK, or create an account for anyone other than
                      yourself without permission;
                      <br />
                      (e) impersonate any person or entity or falsely state or otherwise misrepresent your affiliation
                      with a person or entity;
                      <br />
                      (f) violate or attempt to violate the security of any of the Services, including by: logging or
                      attempting to log in to a server or account that you are not authorized to access; testing,
                      scanning, probing or hacking the vulnerability of the Site or any network used by the Site or to
                      breach security, encryption or other authentication measures or attempting to any of the
                      foregoing; or interfering with the Site by overloading, flooding, pinging, mail bombing or
                      crashing it or attempting to do any of the foregoing;
                      <br />
                      (g) share your password, permit, authorize or enable anyone else to access your account, or do
                      anything else that could reasonably be expected to jeopardize the security of your account;
                      <br />
                      (h) reverse engineer, decompile, disassemble or otherwise attempt to reconstruct, identify or
                      discover any source code, underlying ideas, underlying user interface techniques, algorithms or
                      any other aspect or element of any portion of the Services;
                      <br />
                      (i) “scrape” UPSTACK Information from the Services, or use or attempt to use any engine, software,
                      tool, agent or other device or mechanism (including browsers, spiders, bots or robots, avatars or
                      intelligent agents) to navigate or search any portion of the Site, other than through use of the
                      search engine and search agents available from UPSTACK on the Site and generally available to
                      third party web browsers (e.g., Mozilla Firefox, Google Chrome, Apple Safari and Microsoft
                      Internet Explorer); or
                      <br />
                      (j) harvest or collect UPSTACK Information, email addresses or other contact information of other
                      users from the Site or any Service by electronic or other means for the purposes of sending
                      unsolicited emails or other unsolicited communications.
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14"
                      weight="medium">
                      7. ADDITIONAL RESTRICTIONS/OBLIGATIONS
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5 ml-5"
                      size="sm14">
                      (a) You may not collect, develop or derive for commercial sale any data in any form that
                      incorporates or uses any UPSTACK Information.
                      <br />
                      (b) You may not modify, copy, distribute, download for storage on a hard drive or any other
                      storage media, display, use, publish, license, create derivative works from, transfer or sell any
                      UPSTACK Information.
                      <br />
                      (c) You shall: (i) provide true, accurate, current and complete information about yourself when
                      entering your information on the Site and when communicating with Third Party Organizations; and
                      (ii) maintain and update this information to keep it true, accurate, current, and complete.
                      <br />
                      (d) You shall abide by all copyright notices or restrictions contained in any content accessed
                      through the Site.
                      <br />
                      (e) You may not under any circumstances publish, copy, distribute, share with third parties or
                      make public in any form any information regarding the Third Party Services or Third Party
                      Organizations, including pricing information, location of the third party supplier or its
                      facilities, availability of Third Party Services or service levels, no matter how obtained
                      (whether through the Services or directly from the Third Party Organizations).
                      <br />
                      (f) You agree to hold any Confidential Information (as defined below) you obtain as a result of
                      using this Site in confidence and, unless required by law, not to make the Confidential
                      Information available to any third party or to use the Confidential Information for any purpose
                      other than the business activities for which you were given access to the Confidential
                      Information. You agree to take all reasonable steps to ensure that Confidential Information is not
                      disclosed or distributed by you, your employees, your employer or agents in violation of these
                      Terms. For purposes of these Terms, “Confidential Information” means any and all proprietary
                      information labeled as “confidential” or which a reasonable person would know constitutes
                      proprietary or sensitive information, including but not limited to, communications between you and
                      another user, pricing information, business plans, financial reports, customer lists and other
                      customer information, descriptions of colocation centers, and marketing plans.
                      <br />
                      (g) You agree that aspects of this Site, including the specific information regarding business
                      needs of a customer and pricing information of a vendor constitute trade secrets of the person
                      providing such information, and shall implement reasonable security measures to protect such trade
                      secrets.
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14"
                      weight="medium">
                      8. SUBMISSIONS
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14">
                      All remarks, suggestions, ideas, graphics, or other information (“Submissions”) communicated to
                      UPSTACK through this Site will forever be the property of UPSTACK. You are solely responsible for
                      the content of any Submission you make you make on this Site or to any Third Party Organization
                      through the Site. Unless otherwise specified in writing, all Submissions made through or on the
                      Site will be presumed to be public and UPSTACK will not be required to treat the information as
                      confidential. The content of Submissions is not pre-screened. Accordingly, users who access
                      Submissions publically available do so at their own risk, especially with respect to offensive,
                      incorrect, and otherwise unacceptable material. Notwithstanding the foregoing, UPSTACK reserves
                      the right, but does not assume any obligation, to delete Submissions that UPSTACK, in its sole
                      discretion, deems abusive, defamatory, obscene, in violation of any Laws, or otherwise
                      unacceptable.
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14"
                      weight="medium">
                      9. YOUR INDEMNITY OBLIGATIONS
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14">
                      You hereby indemnify, and agree to defend and hold UPSTACK and each Third Party Organization with
                      whom you engage on, through or as a result of your use of the Services, and each of their
                      respective affiliates, as well as each such party’s respective officers, directors, shareholders,
                      employees and representatives harmless from and against any claims, losses, costs, expenses or
                      damages, including reasonable legal fees, arising out of or relating to the Services, your use of
                      and access to the Site or the Services, your violation of any rights of another, or resulting from
                      your breach of these Terms or arising out of a breach of your obligations, representation and
                      warranties under these Terms.
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14"
                      weight="medium">
                      10. TERMINATION
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14">
                      You agree that UPSTACK, in its sole discretion, may suspend or terminate your account (or any part
                      thereof), your access to the Site or use of the Services and remove and discard any content within
                      the Services, for any reason. If UPSTACK suspects fraudulent, abusive or illegal activity, UPSTACK
                      may refer content or accounts to law enforcement authorities. UPSTACK will not be liable to you or
                      any third-party for any termination of your access to the Services.
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14"
                      weight="medium">
                      11. DISCLAIMER OF WARRANTIES
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14">
                      YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. THE SERVICES, INCLUDING ALL THE MATERIALS,
                      INFORMATION, FACILITIES AND OTHER CONTENT AVAILABLE ON THIS SITE ARE PROVIDED ON AN “AS IS” AND
                      “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. YOU ASSUME
                      THE RISK OF ANY AND ALL DAMAGE OR LOSS FROM USE OF, OR INABILITY TO USE, THE SITE OR SERVICE. TO
                      THE MAXIMUM EXTENT PERMITTED BY LAW, UPSTACK EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES, EXPRESS OR
                      IMPLIED, REGARDING THE SITE AND SERVICE, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF
                      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. UPSTACK DOES NOT WARRANT
                      THAT THE SITE OR SERVICE WILL MEET YOUR REQUIREMENTS OR THAT THE OPERATION OF THE SITE WILL BE
                      AVAILABLE CONTINUOUSLY, UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE
                      SITE OR THE SERVERS THAT MAKE THE SITE AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
                      UPSTACK DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE
                      OF THE MATERIAL, INFORMATION, SOFTWARE, FACILITIES, SERVICES OR OTHER CONTENT ON OR MADE AVAILABLE
                      THROUGH THE SITE OR ANY WEBSITES LINKED TO THIS SITE IN TERMS OF THEIR QUALITY, CORRECTNESS,
                      ACCURACY, RELIABILITY, OR OTHERWISE. UPSTACK MAKES NO WARRANTIES THAT YOUR USE OF THE MATERIALS,
                      INFORMATION, SOFTWARE, FACILITIES, SERVICES OR OTHER CONTENT ON THE SITE OR ANY OTHER WEBSITE WILL
                      NOT INFRINGE THE RIGHTS OF OTHERS. UPSTACK ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ERRORS OR
                      OMISSIONS IN SUCH MATERIALS, INFORMATION, SOFTWARE, FACILITIES, SERVICES OR OTHER CONTENT ON THIS
                      SITE OR ANY OTHER WEBSITE. IF APPLICABLE LAW DOES NOT ALLOW THE EXCLUSION OF SOME OR ALL OF THE
                      ABOVE IMPLIED WARRANTIES TO APPLY TO YOU, THE ABOVE EXCLUSIONS WILL APPLY TO YOU ONLY TO THE
                      EXTENT PERMITTED BY APPLICABLE LAW.
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14"
                      weight="medium">
                      12. LIMITATION OF LIABILITY
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14">
                      UPSTACK’S LIABILITY TO YOU IS LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW AND MAY NOT EXCEED
                      THE FEES YOU HAVE PAID UPSTACK FOR USE OF THE SERVICE. IN NO EVENT SHALL UPSTACK, ITS AFFILIATES,
                      OR EITHER’S RESPECTIVE DIRECTORS, EMPLOYEES, SHAREHOLDERS, MEMBERS, AGENTS OR REPRESENTATIVES BE
                      LIABLE TO YOU FOR DAMAGES OF ANY KIND (INCLUDING, BUT NOT LIMITED TO, SPECIAL, INCIDENTAL,
                      PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES, LOST PROFITS, OR LOST DATA, REGARDLESS OF THE
                      FORESEEABILITY OF THOSE DAMAGES) ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SITE OR
                      SERVICE OR ANY OTHER MATERIALS OR SERVICES PROVIDED TO YOU BY UPSTACK. THIS LIMITATION SHALL APPLY
                      REGARDLESS OF WHETHER THE DAMAGES ARISE OUT OF BREACH OF CONTRACT, TORT, OR ANY OTHER LEGAL THEORY
                      OR FORM OF ACTION.
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14"
                      weight="medium">
                      13. PRIVACY
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14">
                      Your use of the Services is subject to UPSTACK’s Privacy Policy at
                      https://upstack.com/privacy-policy/, the provisions of which are incorporated herein by this
                      reference. By using the Services, you acknowledge and consent to be bound by the terms of
                      UPSTACK’s Privacy Policy, including UPSTACK’s collection, use and disclosure of your personal
                      information.
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14"
                      weight="medium">
                      14. GOOGLE MAPS AND GOOGLE GEOLOCATION
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14">
                      This Site uses Google Maps and Google Maps Geolocation APIs under license from Google Inc. By
                      using Google Maps and Google Maps Geolocation while accessing the Services, you agree to be bound
                      by Google’s Terms of Service available at https://www.google.com/intl/en/policies/terms/.
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14"
                      weight="medium">
                      15. CHANGE IN CONTROL
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14">
                      In the event of a change of control of UPSTACK or the sale of substantially all of UPSTACK assets
                      or other corporate event, all rights of UPSTACK hereunder shall be transferable without notice to
                      you.
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14"
                      weight="medium">
                      16. GOVERNING LAW & JURISDICTION
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14">
                      These Terms shall be governed by, construed and enforced in accordance with the laws of the United
                      States of America and the laws of the State of New York. You hereby consent and submit to the
                      exclusive jurisdiction of the courts of the County of New York, State of New York for any action
                      however so arising out of these Terms.
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14">
                      The Terms constitute the entire agreement between you and UPSTACK and govern your use of the
                      Services, superseding any prior agreements between you and UPSTACK with respect to the Services.
                      You also may be subject to additional terms and conditions that may apply when you use affiliate
                      services, third-party content or third-party software. To the extent that there is any
                      irreconcilable conflict between the Service Agreement entered into between UPSTACK and your
                      Commercial Principal and these Terms, the Service Agreement shall prevail.
                    </TextBlock>
                    <TextBlock
                      className="text-justify mb-5"
                      size="sm14">
                      In the event that any provision of these Terms is held to be invalid or unenforceable, then that
                      provision will be limited to the minimum extent necessary, and the remaining provisions of these
                      Terms will remain in full force and effect.
                    </TextBlock>
                  </div>
                )}
                <Icon
                  className="p-2 cursor-pointer top-2 right-2 absolute border"
                  onClick={handleExpand}
                  title="expand/collapse"
                  type={isExpanded ? 'collapse' : 'expand'}
                />
              </div>
              <div className="mt-5 grid grid-cols-2 justify-items">
                <div>
                  <Link
                    className="text-grey rounded text-sm font-bold py-2 cursor-pointer"
                    href="/Portal Terms of User.pdf"
                    role="link"
                    target="_blank"
                    underline="none">
                    Download as a PDF
                  </Link>
                </div>
                <div className="justify-self-end">
                  <button
                    className={`${DEFAULT_ADVANCE_BUTTON_STYLE}`}
                    disabled={isAccepted}
                    onClick={() => handleAccept()}
                    type="button">
                    Accept
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
