export const DEFAULT_ADVANCE_BUTTON_STYLE =
  'bg-indigo enabled:hover:bg-violet-600 text-white rounded text-sm font-bold py-2 px-5 disabled:opacity-25';
export const DEFAULT_CANCEL_BUTTON_STYLE =
  'bg-grey-1 enabled:hover:bg-grey-2 text-black rounded text-sm font-bold py-2 px-5 disabled:opacity-25';
export const RAISED_DIALOG = 'm-auto bg-white rounded-xl border shadow-lg p-8';
export const INPUT_STYLE =
  'w-full h-[2.5rem] border border-gray-300 bg-white rounded-md focus:ring-blue-500 focus:border-blue-500 p-2';
export const SELECT_INPUT_STYLE =
  'w-full py-2 px-4 my-2 text-[14px] text-gray-700 leading-tight border border-gray-300  rounded-md bg-white focus:outline-none focus:shadow-outline-blue';
export const BORDER_STYLE = 'border border-1 border-grey-2 rounded-lg';
