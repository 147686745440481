export const IconTypes = {
  account: 'account-icon',
  orders: 'orders-icon',
  inventory: 'inventory-icon',
  'support-ticketing': 'support-ticketing-icon',
  ordersPage: 'orders-page',
  inventoryPage: 'inventory-page',
  supportPage: 'support-page',
  triangle: 'nav-triangle',
  checked: 'box-check',
  hidden: 'box-check-hidden',
  dropdown: 'dropdown-triangle',
  customize: 'menu-customize-button',
  sort: 'menu-sort-button',
  filter: 'menu-filter-button',
  dots: 'menu-group-button',
  calendar: 'calendar-button',
  details: 'details-button',
  more: 'more-icon',
  collapse: 'collapse-icon',
  expand: 'expand-icon',
  plus: 'plus-circle',
  plusRectangle: 'plus-button',
  minus: 'minus-button',
  circlemark: 'circlemark',
  homeIcon: 'home-icon-sidebar',
  ordersIcon: 'orders-icon-sidebar',
  inventoryIcon: 'inventory-icon-sidebar',
  ticketsIcon: 'tickets-icon-sidebar',
  commissionsIcon: 'commissions-icon',
  logoutIcon: 'logout-icon-sidebar',
  greyCheckmark: 'grey-checkmark',
  greenCheckmark: 'green-checkmark',
  indigoCheckmark: 'indigo-checkmark',
  alert: 'alert-circle',
  cancel: 'cancel-button',
  retry: 'retry-button',
  trash: 'trash-button',
  myTeam: 'my-team-icon',
  pin: 'pin',
  redCancel: 'red-cancel',
  impersonate: 'impersonate-icon',
  key: 'key',
  settings: 'settings-icon',
  hasNotifications: 'has-notifications-icon',
  noNotifications: 'no-notifications-icon',
  notificationsDot: 'notifications-dot',
  notificationsReadDot: 'notifications-read-dot',
  contact: 'contact-icon',
  logoutSmall: 'logout-small-icon',
  refresh: 'refresh',
  newDoc: 'newDoc',
  external: 'external',
  info: 'info-icon',
  edit: 'edit-icon',
  disabled: 'disabled-icon',
  enabled: 'enabled-icon',
  filterIcon: 'filter-icon',
  densityIcon: 'density-icon',
  columnsIcon: 'columns-icon',
  exportIcon: 'export-icon'
};

export interface IconProps {
  children?: React.ReactNode;
  className?: string;
  type: IconType;
  size?: string;
  onClick?: () => void;
  title?: string;
}

export type IconType = keyof typeof IconTypes;

export const Icon = ({ className = '', type, size = '', children, ...props }: IconProps) => {
  return (
    <div
      {...props}
      className={`${IconTypes[type]} ${size} ${className}`}>
      {children}
    </div>
  );
};
