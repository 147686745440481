import Popup from 'reactjs-popup';
import { useState } from 'react';
import { ContactUsForm } from './ContactUsForm';
import { Icon } from '../../Atoms/Icon';
import { TextSpan } from '../../Atoms/Text';

const contentStyle = {
  borderRadius: '10px',
  border: '2px solid #F7F7F7',
  width: '360px',
  padding: '28px'
};

interface ContactUsLinkProps {
  linkText: string;
  dataTestId?: string;
  textStyle?: string;
}

export function ContactUsLink({ linkText, dataTestId, textStyle }: ContactUsLinkProps) {
  const [open, setOpen] = useState(false);

  const onClose = async () => {
    setOpen(false);
  };

  return (
    <>
      <button
        className="flex items-center space-x-1"
        onClick={() => setOpen(true)}>
        <Icon type="contact" />
        <TextSpan
          className={textStyle || 'text-xs'}
          dataTestId={dataTestId}
          color="grey5">
          {linkText}
        </TextSpan>
      </button>
      <Popup
        contentStyle={contentStyle}
        closeOnDocumentClick={false}
        modal={true}
        open={open}
        onClose={onClose}>
        <ContactUsForm onClose={onClose} />
      </Popup>
    </>
  );
}
