import { ChangeEvent, useEffect, useState } from 'react';
import { Text } from '../../Atoms/Text';
import { FormState, useForm } from 'react-hook-form';
import { InventoryAsset } from '../../../types';
import { fetchSharedAccountCustomizations } from '../../../store/SharedAccountCustomizations';
import { emailRegex } from '../../../utils/helpers';
import AdditionalRecipients from '../../Pages/UserSettings/AdditionalRecipients';
import { defaultFeatureFlags, FeatureFlags, getFeatureFlagSubject } from '../../../Api/useFeatureFlags';
import { TextSpan } from '../../Atoms/Text';

export interface FormData {
  Status: string;
  AccountId: string;
  ContactId: string;
  isServiceDown: string;
  serviceSubject: string;
  Description: string;
  issueType: string;
  Reason: string;
  Priority: string;
  Subject: string;
  OrderProductId: string;
  AdditionalRecipients: string;
  supplierTicketNumber: string;
}

const reasonByType: { [key: string]: string[] } = {
  'Technical Support': [
    'Service 100% Down',
    'Technical Issue',
    'Service Impaired',
    'Configuration Change',
    'Equipment Change',
    'Inside Wiring/Vendor Dispatch',
    'Implementation/Installation',
    'Disconnect/Terminate Service'
  ],
  'Billing Inquiry': [
    'Supplier Portal Support',
    'General Account Inquiry',
    'Internal UPSTACK Support',
    'Billing',
    'RFO/RCA'
  ],
  '': []
};

const picklistValues: { [key: string]: string[] } = {
  issueType: ['Technical Support', 'Billing Inquiry'],
  priority: ['High', 'Medium', 'Low']
};

const selectStyles =
  'flex m-auto rounded-md py-2 px-4 text-[14px] focus:shadow-outline-blue my-3 border border-slate-300 w-[19rem]';

const validateDescription = (value: string) => {
  if (value == 'required') return 'Please enter a detailed description of your issue';
  if (value == 'maxLength') return 'Character count is limited to 2,000';
  return true;
};

const infoText = () => {
  return (
    <Text
      size="sm14"
      className="text-center mt-3 mx-2"
      color="grey5">
      You can upload relevant attachments after you have created your ticket.
    </Text>
  );
};

const addressSelect = (service: InventoryAsset) => {
  const value = service.addressInfo.number ? `${service.addressInfo.number} - ${service.address}` : service.address;
  return (
    <select
      className={selectStyles}
      id="addressList"
      onChange={undefined}>
      <option value="">{value.length > 32 ? `${value.substring(0, 32)}...` : value}</option>
    </select>
  );
};

export interface TicketFormProps {
  service: InventoryAsset;
  onSubmit: (formData: FormData) => Promise<void> | void;
  onError?: () => void;
  onChange?: (formState: FormState<FormData>) => void;
  additionalRecipients: string[];
  setAdditionalRecipients: (newVal: string[]) => void;
  saveDefault: boolean;
  setSaveDefault: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export const TicketForm = ({
  service,
  onSubmit,
  onError,
  onChange,
  additionalRecipients,
  setAdditionalRecipients,
  saveDefault,
  setSaveDefault
}: TicketFormProps) => {
  const [reasonOptions, setReasonOptions] = useState<string[]>([]);
  const [isServiceDown, setIsServiceDown] = useState<boolean | null>(null);
  const [serviceSubject, setServiceSubject] = useState<string>('');
  const { register, handleSubmit, formState, reset } = useForm<FormData>();
  const [additionalRecipInput, setAdditionalRecipInput] = useState<string>();
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>(defaultFeatureFlags);

  useEffect(() => {
    fetchSharedAccountCustomizations();
    const flags = getFeatureFlagSubject().subscribe((flags) => setFeatureFlags(flags));

    return () => {
      if (flags) flags?.unsubscribe();
    };
  }, []);

  const handleResetFormState = () => {
    reset({}, { keepIsValid: false, keepErrors: true, keepDirty: true, keepValues: true });
    if (onError) onError();
  };

  const onAdditionalRecipientsDelete = (email: string) => () => {
    const update = [...additionalRecipients].filter((v) => v !== email);
    setAdditionalRecipients(update);
  };

  const handleAdd = () => {
    if (additionalRecipInput && emailRegex.test(additionalRecipInput)) {
      setAdditionalRecipients([...(additionalRecipients || []), additionalRecipInput]);
      setAdditionalRecipInput('');
    }
  };

  const handleServiceStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsServiceDown(event.target.value === 'true');
  };

  const handleServiceSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setServiceSubject(event.target.value);
  };

  const { errors } = formState;

  return (
    <>
      <form
        data-testid="ticket-creation-form"
        onSubmit={handleSubmit(onSubmit, handleResetFormState)}
        onChange={() => onChange && onChange(formState)}
        id="ticket-creation-form">
        {addressSelect(service)}
        {!featureFlags.ticket_wizard && (
          <>
            <select
              className={`${selectStyles} ${errors.Reason && 'border border-[red]'}`}
              data-cy="issue-type"
              role="combobox"
              aria-label="issueType"
              id="issuetype"
              {...register('issueType', { required: false })}
              onChange={(e) => {
                const selectedIssueType: keyof typeof reasonByType = e.target.value;
                setReasonOptions(reasonByType[selectedIssueType]);
              }}>
              <option value="">Issue Type</option>
              {picklistValues.issueType.map((value) => (
                <option
                  key={value}
                  value={value}>
                  {' '}
                  {value}{' '}
                </option>
              ))}
            </select>
            {errors.issueType && <div className="invalid-feedback">This field is required</div>}
            <select
              className={`${selectStyles} ${errors.Reason && 'border border-[red]'}`}
              data-cy="issue-reason"
              role="combobox"
              aria-label="reason"
              id="reason"
              {...register('Reason', { required: true })}>
              <option value="">Issue Reason</option>
              {reasonOptions.length > 0 &&
                reasonOptions.map((value) => (
                  <option
                    key={value}
                    value={value}>
                    {value}
                  </option>
                ))}
            </select>
            {errors.Reason && <div className="invalid-feedback">This field is required</div>}
            <select
              className={`${selectStyles}`}
              data-cy="issue-priority"
              id="priority"
              role="combobox"
              aria-label="Priority"
              {...register('Priority', { required: false })}>
              <option value="">Priority</option>
              {picklistValues.priority.map((value) => (
                <option
                  key={value}
                  value={value}>
                  {' '}
                  {value}{' '}
                </option>
              ))}
            </select>
          </>
        )}
        {featureFlags.ticket_wizard && (
          <div className="leading-4 m-auto my-3 text-[14px] w-[19rem]">
            <TextSpan size="sm14">Is service out or impaired?</TextSpan>
            <label htmlFor="isServiceDown">
              <br />
              <input
                checked={isServiceDown === true}
                className="mr-2 mt-2 w-2.5 h-2.5"
                id="isServiceDown"
                type="radio"
                {...register('isServiceDown')}
                onChange={handleServiceStatusChange}
                value="true"
              />
              Yes
            </label>
            <label htmlFor="isServiceUp">
              <br />
              <input
                checked={isServiceDown === false}
                className="mr-2 mt-2 w-2.5 h-2.5"
                id="isServiceUp"
                type="radio"
                value="false"
                {...register('isServiceDown')}
                onChange={handleServiceStatusChange}
              />
              No
            </label>
          </div>
        )}
        {featureFlags.ticket_wizard && isServiceDown === false && (
          <div className="leading-4 m-auto my-3 text-[14px] w-[19rem]">
            <TextSpan size="sm14">Request Type</TextSpan>
            <label htmlFor="disconnect">
              <br />
              <input
                checked={serviceSubject === 'Disconnect This Service'}
                className="mt-2 mr-2 w-2.5 h-2.5"
                id="disconnect"
                type="radio"
                value="Disconnect This Service"
                {...register('serviceSubject')}
                onChange={handleServiceSubjectChange}
              />
              Disconnect This Service
            </label>
            <label htmlFor="billing">
              <br />
              <input
                checked={serviceSubject === 'Billing or Administrative Issues'}
                className="mr-2 mt-2 w-2.5 h-2.5"
                id="billing"
                type="radio"
                value="Billing or Administrative Issues"
                {...register('serviceSubject')}
                onChange={handleServiceSubjectChange}
              />
              Billing or Administrative Issues
            </label>
            <label htmlFor="other">
              <br />
              <input
                checked={serviceSubject === 'Other'}
                className="mr-2 mt-2 w-2.5 h-2.5"
                id="other"
                type="radio"
                value="Other"
                {...register('serviceSubject')}
                onChange={handleServiceSubjectChange}
              />
              Other
              <TextSpan
                className="inline-block ml-1"
                color="grey5"
                size="sm">
                (RFO/RCA, Supplier Inquiry, etc.)
              </TextSpan>
            </label>
          </div>
        )}
        {(!featureFlags.ticket_wizard || (isServiceDown === false && serviceSubject !== '') || isServiceDown) && (
          <>
            {(!featureFlags.ticket_wizard || isServiceDown) && (
              <div className="relative after:content-['Optional'] after:absolute after:right-12 after:top-2.5 after:text-gray-400 after:text-sm">
                <input
                  type="text"
                  className={`${selectStyles}`}
                  id="supplierTicketNumber"
                  data-cy="issue-supplier-ticket-number"
                  aria-label="Supplier Ticket Number"
                  placeholder="Supplier Ticket #"
                  {...register('supplierTicketNumber', { required: false })}
                />
              </div>
            )}
            <textarea
              className={`${selectStyles} ${errors.Description && 'border border-[red]'}`}
              data-cy="issue-details"
              id="description"
              {...register('Description', {
                required: true,
                maxLength: 2000,
                validate: validateDescription
              })}
              rows={5}
              placeholder="Details"
            />
            {errors.Description && (
              <div className="invalid-feedback">{validateDescription(errors.Description.type)}</div>
            )}
            <AdditionalRecipients
              additionalRecipients={additionalRecipients}
              handleAdd={handleAdd}
              additionalRecipInput={additionalRecipInput}
              setAdditionalRecipInput={setAdditionalRecipInput}
              saveDefault={saveDefault}
              setSaveDefault={setSaveDefault}
              onAdditionalRecipientsDelete={onAdditionalRecipientsDelete}
              showDefaultBox={true}
              styles={{
                divClassName: 'space-y-2',
                textField: {
                  width: '19rem',
                  margin: 'auto',
                  display: 'flex'
                },
                box: {
                  '& > :not(:last-child)': { marginRight: 1 },
                  '& > *': { marginBottom: 1 },
                  width: '20.5rem',
                  marginLeft: 'auto',
                  maxHeight: '8rem',
                  overflow: 'auto'
                },
                chip: {
                  marginBottom: '.5rem',
                  borderRadius: '5px'
                },
                formControlLabel: {
                  display: 'flex',
                  margin: 'auto',
                  width: '20rem'
                }
              }}
            />
            {infoText()}
          </>
        )}
      </form>
    </>
  );
};
