import { DEFAULT_ADVANCE_BUTTON_STYLE } from '../../../utils/styleHelpers';
import { getCurrentPreferences, updateUserSettings } from '../../../store/User';
import { useState } from 'react';
import TextField from '@mui/material/TextField';

export type MenuRowProps = {
  handleCustomTabChange: (name: string) => void;
  name: string;
  index: number;
  changeView: (name: string, newName: string, reset?: boolean) => void;
};

export function MenuRow({ index, name, handleCustomTabChange, changeView }: MenuRowProps) {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [newName, setNewName] = useState<string>('');

  const deleteView = () => {
    changeView('', '', true);
    const currentSettings = JSON.parse(JSON.stringify(getCurrentPreferences()));
    delete currentSettings.content[`inventory_grid_${name}`];
    updateUserSettings(currentSettings, `inventory_grid_${name}`);
  };

  const handleSubmitForm = (): void => {
    const currentSettings = JSON.parse(JSON.stringify(getCurrentPreferences()));
    const currentViewData = { ...currentSettings.content[`inventory_grid_${name}`] };
    delete currentSettings.content[`inventory_grid_${name}`];
    currentViewData.name = newName;
    currentSettings.content[`inventory_grid_${newName}`] = currentViewData;

    updateUserSettings(currentSettings, `inventory_grid_${newName}`);
    setIsEdit(false);
    changeView(name, newName);
  };

  if (isEdit) {
    return (
      <div className="flex border-b py-2">
        <TextField
          size="small"
          label="name"
          variant="outlined"
          value={newName || name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setNewName(event.target.value);
          }}
        />
        <button
          onClick={handleSubmitForm}
          className={`${DEFAULT_ADVANCE_BUTTON_STYLE} py-[0.2rem] ml-auto`}>
          Save
        </button>
      </div>
    );
  } else {
    return (
      <div className="flex border-b py-2">
        <button
          className="menu-item truncate max-w-44 outline-none"
          onClick={() => handleCustomTabChange(name)}>
          <span>{name}</span>
        </button>
        <button
          className="ml-auto text-[indigo] text-sm mr-2"
          onClick={() => setIsEdit(true)}>
          Rename
        </button>
        <button
          className="ml-auto text-[indigo] text-sm"
          onClick={deleteView}>
          Delete
        </button>
      </div>
    );
  }
}
