import {
  DataGridPro,
  GridColDef,
  GridPinnedColumnFields,
  GridSingleSelectColDef,
  GridValidRowModel,
  useGridApiRef
} from '@mui/x-data-grid-pro';
import { ComponentSpinner } from '../Loading/ComponentSpinner';
import {
  GridPreferences,
  PrefKey,
  getCurrentPreferences,
  setPreferences,
  updateUserSettings
} from '../../../store/User';
import { CustomToolbar } from './CustomToolbar';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { CustomFilterPanel } from './CustomFilterPanel';
import { Icon } from '../../Atoms/Icon';
import { updateGridRef } from '../../../store/GeneralStore';

export interface UpstackDataGridProps {
  title: string;
  columns: GridSingleSelectColDef[] | GridColDef[] | undefined;
  rows: GridValidRowModel[];
  loadingData: boolean;
  page: PrefKey;
  gridSettings: GridPreferences;
  showSearch: boolean;
  pinnedColumns?: GridPinnedColumnFields;
  dataCy?: string;
}

declare module '@mui/x-data-grid-pro' {
  interface ToolbarPropsOverrides {
    title: string;
    showSearch: boolean;
    setFilterButtonEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  }
}

export const DEFAULT_TABLE_STYLES = {
  sx: {
    overflowX: 'scroll',
    overflowY: 'hidden',
    border: 'unset',
    '.MuiDataGrid-cell': {
      fontFamily: 'sans-serif',
      borderLeft: '.5px solid lightgrey',
      '&:first-child': {
        borderLeft: 'unset'
      },
      '&:last-child': {
        borderRight: 'unset'
      }
    },
    '.MuiDataGrid-overlayWrapper': {
      minHeight: '10rem'
    },
    '.MuiDataGrid-columnHeader': {
      fontFamily: 'sans-serif',
      fontSize: '.75rem',
      color: '#878EBE',
      borderLeft: '.5px solid lightgrey',
      borderTop: '.5px solid lightgrey',
      '&:first-child': {
        borderLeft: 'unset !important'
      },
      '&:last-child': {
        borderRight: 'unset'
      }
    }
  },
  headerHeight: 40,
  rowHeight: 40
};

const columnsIcon = () => {
  return <Icon type="columnsIcon" />;
};

const exportIcon = () => {
  return <Icon type="exportIcon" />;
};

const filterIcon = () => {
  return <Icon type="filterIcon" />;
};

export const UpstackDataGrid = ({
  title,
  rows,
  columns,
  loadingData,
  page,
  gridSettings = {} as GridPreferences,
  showSearch = false,
  pinnedColumns,
  dataCy = ''
}: UpstackDataGridProps) => {
  const apiRef = useGridApiRef();
  const [initialState, setInitialState] = useState<GridInitialStatePro>();
  const [hiddenColumns, setHiddenColumns] = useState<string[]>();
  const [filterableColumns, setFilterableColumns] = useState<GridSingleSelectColDef[] | GridColDef[]>();
  const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (columns && hiddenColumns) {
      const updatedColumns = columns.map((column) => ({
        ...column,
        filterable: !hiddenColumns.includes(column.field) && !['details', 'detailCTA'].includes(column.field)
      }));
      setFilterableColumns(updatedColumns);
    } else {
      setFilterableColumns([]);
    }
  }, [hiddenColumns, columns]);

  const saveState = useCallback(() => {
    if (apiRef?.current?.exportState) {
      const currentState = apiRef.current.exportState();
      const update = { ...gridSettings, muiConfig: currentState };
      const currentSettings = getCurrentPreferences();
      if (currentSettings?.content) {
        (currentSettings.content[page] as GridPreferences) = update;
        setPreferences(currentSettings);
        updateUserSettings(currentSettings, page);
      }
    }
  }, [apiRef]);

  useEffect(() => {
    updateGridRef(page, apiRef);
  }, [apiRef]);

  useLayoutEffect(() => {
    window.addEventListener('beforeunload', saveState);
    return () => {
      window.removeEventListener('beforeunload', saveState);
      saveState();
    };
  }, [saveState]);

  useEffect(() => {
    if (apiRef.current) {
      const state = { ...gridSettings.muiConfig };

      if (pinnedColumns) {
        state.pinnedColumns = pinnedColumns;
        const { columns = {} } = state;
        const { left = [], right = [] } = pinnedColumns;
        const orderedFields = [
          ...left,
          ...(gridSettings?.muiConfig?.columns?.orderedFields || []).filter(
            (field) => !left.includes(field) && !right.includes(field)
          ),
          ...right
        ];
        columns.orderedFields = orderedFields;
        state.columns = columns;
      }
      setInitialState(state || {});

      const hiddenColumns = gridSettings?.muiConfig?.columns?.columnVisibilityModel
        ? Object.keys(gridSettings.muiConfig.columns.columnVisibilityModel).filter(
            (field) => !(gridSettings.muiConfig.columns?.columnVisibilityModel || {})[field]
          )
        : [];
      setHiddenColumns(hiddenColumns);
    }
  }, [gridSettings, apiRef.current]);

  useEffect(() => {
    if (initialState && apiRef?.current?.restoreState) apiRef.current.restoreState(initialState);
  }, [initialState]);

  useEffect(() => {
    if (apiRef?.current?.setFilterModel) {
      if (gridSettings?.muiConfig?.filter?.filterModel?.items?.length) {
        apiRef.current.setFilterModel?.(gridSettings.muiConfig.filter?.filterModel);
      } else {
        apiRef.current.setFilterModel?.({ items: [] });
      }
    }
  }, [apiRef?.current?.setFilterModel, gridSettings]);

  return (
    <>
      {(loadingData || !initialState) && (
        <div className="h-[45rem] p-32">
          <ComponentSpinner />
        </div>
      )}
      {!loadingData && initialState && filterableColumns && (
        <div className="h-[45rem]">
          <DataGridPro
            rows={rows}
            data-cy={dataCy}
            data-testid="data-grid"
            columns={filterableColumns}
            apiRef={apiRef}
            columnHeaderHeight={DEFAULT_TABLE_STYLES.headerHeight}
            rowHeight={DEFAULT_TABLE_STYLES.rowHeight}
            sx={DEFAULT_TABLE_STYLES.sx}
            pagination
            disableRowSelectionOnClick
            initialState={initialState}
            slots={{
              toolbar: CustomToolbar,
              filterPanel: CustomFilterPanel,
              columnMenuIcon: columnsIcon,
              exportIcon: exportIcon,
              openFilterButtonIcon: filterIcon
            }}
            slotProps={{
              panel: {
                anchorEl: filterButtonEl
              },
              toolbar: {
                showSearch,
                title,
                setFilterButtonEl
              }
            }}
            onColumnVisibilityModelChange={(columnVisibility) => {
              const updatedHiddenColumns = Object.keys(columnVisibility).filter((field) => !columnVisibility[field]);
              setHiddenColumns(updatedHiddenColumns);
            }}
            pageSizeOptions={[50, 100, 200]}
          />
        </div>
      )}
    </>
  );
};
