import React, { useState, useEffect, useCallback } from 'react';
import { WidgetProps } from '@rjsf/utils';
import { Autocomplete, TextField } from '@mui/material';
import { INPUT_STYLE } from '../../../../../utils/styleHelpers';
import { useJsApiLoader } from '@react-google-maps/api';
import debounce from 'lodash.debounce';
import { DetailRow } from '../../../DetailsDrawer/DetailRow';

export const ProductAddressInput = (props: WidgetProps) => {
  const { value, onChange } = props;
  const [userInput, setUserInput] = useState(value || '');
  const [dirty, setDirty] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(value || '');
  const [geocodedAddresses, setGeocodedAddresses] = useState<string[]>([]);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''
  });

  const handleGeocode = useCallback(
    debounce(async (address: string) => {
      if (!address) return setGeocodedAddresses([]);
      /**
       * @documentation https://developers.google.com/maps/documentation/javascript/reference/autocomplete-data?db=gems#AutocompleteRequest
       * @example https://developers.google.com/maps/documentation/javascript/examples/place-autocomplete-data-session
       */
      const places = await google.maps.importLibrary('places');
      // @ts-expect-error type
      const { suggestions } = await places.AutocompleteSuggestion.fetchAutocompleteSuggestions({
        input: address
      });
      // @ts-expect-error type
      setGeocodedAddresses(suggestions.map((sugg) => sugg.placePrediction.text.toString()));
    }, 250),
    []
  );

  useEffect(() => {
    if (isLoaded && process.env.NODE_ENV !== 'test') handleGeocode(userInput);
  }, [userInput]);

  const handleChange = (e: React.SyntheticEvent<Element, Event>, value: string | null) => {
    if (!dirty) setDirty(true);
    setUserInput(value || '');
    onChange(value || '');
  };

  const handleInputChange = (e: React.SyntheticEvent<Element, Event>, value: string | null) => {
    if (!dirty) setDirty(true);
    setUserInput(value || '');
    setSelectedAddress(value || '');
    onChange(value || '');
  };

  const getHelperText = () => {
    if (isErrorState()) return 'Address is required';
  };

  const isErrorState = () => {
    return dirty && ((props.rawErrors && props.rawErrors.length > 0) || selectedAddress === '');
  };

  if (props.readonly) {
    if (props.formContext?.hideEmpty && !value) return null;

    return (
      <div>
        <DetailRow
          key={props.id}
          colId={props.id}
          title={props.uiSchema?.['ui:placeholder'] || ''}
          value={value || ''}
        />
      </div>
    );
  }

  return (
    <Autocomplete
      value={selectedAddress || userInput}
      inputValue={userInput}
      onInputChange={handleInputChange}
      ListboxProps={{ style: { maxHeight: 150 } }}
      onChange={handleChange}
      onBlur={() => setDirty(true)}
      onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
      options={geocodedAddresses}
      getOptionLabel={(option: string) => option}
      freeSolo={true}
      filterOptions={(options) => options}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.uiSchema?.['ui:placeholder']}
          error={isErrorState()}
          helperText={getHelperText()}
          required={props.required}
          InputProps={{
            ...params.InputProps,
            style: { fontSize: '.875rem' },
            name: props.uiSchema?.['ui:placeholder']
          }}
          className={INPUT_STYLE}
        />
      )}
      renderOption={(props, option) => (
        <li
          {...props}
          className="text-sm14 py-1 pl-4 cursor-pointer hover:bg-grey-1">
          {option}
        </li>
      )}
    />
  );
};

export default ProductAddressInput;
