import { FunctionComponent, useEffect, useState } from 'react';
import './App.css';
import { NavBar } from './components/Compounds/Navigation/NavBar';
import { SideNavBar } from './components/Compounds/Navigation/SideNavBar';
import AppRoutes from './components/AppRoutes';
import { LoadingIconPage } from './loadingIcon';
import { getUserLoadingSubject, getUserSubject, useSharedUser } from './store/User';
import { defaultFeatureFlags, FeatureFlags, getFeatureFlagSubject, useFeatureFlags } from './Api/useFeatureFlags';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSession } from './Api/useSession';
import { ImpersonationBanner } from './components/Compounds/UserCreation/ImpersonationBanner';
import { User } from './types';
import PublicAppRoutes from './components/PublicAppRoutes';
import { TosModal } from './components/Pages';
import { useErrorStore } from './store/Error';
import { useGeneralStore } from './store/GeneralStore';
import { LicenseInfo } from '@mui/x-license';
import { useAccounts } from './store/Accounts';

const UNPROTECTED_ROUTES = ['/portal/offline', '/reset', '/welcome'];

const App: FunctionComponent = () => {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE || '');
  // Init general store, user, and feature flags.
  useFeatureFlags();
  useGeneralStore();
  useSharedUser();

  const { errorMessageSubscription } = useErrorStore();
  const { tokenLoading, clearSession } = useSession();
  const [userLoading, setUserLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { fetchAccounts, accountsLoading } = useAccounts();
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>(defaultFeatureFlags);
  const [params] = useSearchParams();
  const [user, setUser] = useState<User | null>(null);
  const showFetching: boolean = [tokenLoading, userLoading, accountsLoading].some((loadingState) => loadingState);

  if (featureFlags.maintenance_mode) navigate('/portal/offline');
  if (!featureFlags.maintenance_mode && location.pathname == '/portal/offline') window.location.href = '/';

  useEffect(() => {
    const flags = getFeatureFlagSubject().subscribe((flags) => setFeatureFlags(flags));
    const userSubscription = getUserSubject().subscribe((user) => setUser(user));
    const userLoadingSub = getUserLoadingSubject().subscribe((loading) => setUserLoading(loading));
    const errorSubscription = errorMessageSubscription.subscribe((errors) => {
      if (!errors) return;

      const eLower = errors.toLowerCase();
      if (
        errors.includes('401') ||
        eLower.includes('token') ||
        eLower.includes('authenticated') ||
        eLower.includes('Not Authorized')
      ) {
        return (
          location.pathname !== '/login' &&
          location.pathname !== '/reset' &&
          location.pathname !== '/portal/offline' &&
          clearSession('Session expired. Please login again')
        );
      }

      if (errors?.includes('403') || (errors?.includes('404') && location.pathname.includes('/login'))) return;
    });

    fetchAccounts();

    return () => {
      if (flags) flags?.unsubscribe();
      if (userSubscription) userSubscription.unsubscribe();
      if (errorSubscription) errorSubscription.unsubscribe();
      if (userLoadingSub) userLoadingSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const path = location.pathname;

    if (!localStorage.getItem('token') && !UNPROTECTED_ROUTES.includes(path)) {
      clearSession();
    } else {
      const redirectTo: string | null = params.get('redirectTo');
      if (redirectTo) navigate(redirectTo);
    }
  }, []);

  useEffect(() => {
    fetchAccounts();
  }, [user?.id]);

  const publicPage = featureFlags.maintenance_mode;

  if (user?.id && !user.tos_read) {
    return (
      <div className="h-screen flex">
        <div
          data-testid="private-app"
          className="flex flex-col basis-full main-container">
          <ImpersonationBanner />
          <TosModal />
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex">
      {user && user.id && !publicPage && <SideNavBar />}
      {showFetching && <LoadingIconPage />}
      {!showFetching && !publicPage && user && user.id ? (
        <div
          data-testid="private-app"
          className="flex flex-col basis-full main-container">
          <ImpersonationBanner />
          <NavBar />
          <AppRoutes />
        </div>
      ) : (
        <div
          data-testid="public-app"
          className="flex flex-col basis-full main-container">
          <PublicAppRoutes />
        </div>
      )}
    </div>
  );
};

export default App;
