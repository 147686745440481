import { ChangeEvent, useEffect, useState } from 'react';
import Link from '@mui/material/Link';
import { TextBlock } from '../../Atoms/Text';
import { useSession } from '../../../Api/useSession';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DEFAULT_ADVANCE_BUTTON_STYLE, RAISED_DIALOG, INPUT_STYLE } from '../../../utils/styleHelpers';
import { getErrorMessageSubject, resetErrorStore } from '../../../store/Error';
import { getAccountScope, getUser, isOnlyCommissionsRole } from '../../../store/User';

export interface LoginFormData {
  password: string;
  email: string;
}

export interface ForgotFormData {
  redirect_url?: string;
  email: string;
}

export function LoginModal() {
  const { fetchToken, forgotPassword } = useSession();
  const [formData, setFormData] = useState<Partial<LoginFormData> | Partial<ForgotFormData> | undefined>();
  const isFromMail = window.location.href.includes('welcome');
  const [loginForm, setLoginForm] = useState(!isFromMail);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [success, setSuccess] = useState<boolean>(false);
  const [params] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const errorSubscription = getErrorMessageSubject().subscribe((err) => {
      if (err) {
        if (err.includes('401')) err = 'Password or Email incorrect please try again.';
        if (err.includes('404'))
          err = "Email doesn't exist, please try another or contact us at product.support@upstack.com";
        if (err.includes('Network Error')) err = 'Server not available, please try again later.';
        resetErrorStore();
      }
      setErrorMessage(err);
    });

    return () => {
      if (errorSubscription) errorSubscription.unsubscribe();
    };
  }, []);

  const handleChange = (name: string, e: ChangeEvent<HTMLInputElement>) => {
    if (errorMessage) setErrorMessage('');
    setFormData({ ...formData, [name]: e.target.value.trim() });
  };

  const handleSubmit = async (e?: React.KeyboardEvent<HTMLInputElement>) => {
    e?.preventDefault();

    if (loginForm) {
      await fetchToken(formData as LoginFormData);
      if (!errorMessage && localStorage.getItem('token')) {
        const redirect = isOnlyCommissionsRole(getUser()) ? '/commissions' : params.get('redirectTo');
        const validRedirect =
          redirect?.includes('login') || redirect?.includes('reset') || !getAccountScope() ? '/' : redirect;
        navigate(validRedirect || '/');
      }
    } else {
      const origin = window.location.origin;
      const update = { ...formData, redirect_url: `${origin}/reset` };
      setFormData(update);
      const res = await forgotPassword(update as ForgotFormData);
      if (!res?.error) setSuccess(true);
    }
  };

  return (
    <div className="flex justify-center h-full">
      <div className="grid place-items-center">
        <div className={RAISED_DIALOG}>
          <div className="p-1">
            <div className="w-full">
              <img
                alt="UPSTACK Logo"
                className="m-auto w-36"
                src="/UPSTACK_logo_with_text.svg"
              />
            </div>
            <div className="my-6 border-t"></div>
            <TextBlock
              className="text-center font-medium"
              size="md"
              weight="bold">
              {isFromMail ? 'Account Setup' : 'Sign In'}
            </TextBlock>
            {!success && (
              <form className="bg-white px-1 pt-6 mb-4">
                <label
                  className="text-gray-700 text-sm mb-5"
                  htmlFor="email">
                  Email
                </label>
                <input
                  className={`mb-4 ${INPUT_STYLE}`}
                  data-cy="email"
                  id="email"
                  onChange={(e) => handleChange('email', e)}
                  onKeyDown={(e) => e.key === 'Enter' && handleSubmit(e)}
                  type="text"
                />
                {loginForm && (
                  <div>
                    <label
                      className="text-gray-700 text-sm"
                      htmlFor="password">
                      Password
                    </label>
                    <input
                      className={INPUT_STYLE}
                      data-cy="password"
                      id="password"
                      onChange={(e) => handleChange('password', e)}
                      onKeyDown={(e) => e.key === 'Enter' && handleSubmit(e)}
                      type="password"
                    />
                  </div>
                )}
                <TextBlock
                  className="max-w-xs break-normal"
                  color="red"
                  size="sm">
                  {errorMessage}
                </TextBlock>
                <div className="mt-8">
                  <button
                    className={`${DEFAULT_ADVANCE_BUTTON_STYLE} w-full`}
                    data-cy="sign-in"
                    onClick={() => handleSubmit()}
                    type="button">
                    {loginForm ? 'Sign In' : 'Reset Password'}
                  </button>
                </div>
                <div className="text-center mt-5">
                  <Link
                    className="text-grey rounded text-sm font-bold py-2 px-5 cursor-pointer"
                    color="inherit"
                    onClick={() => {
                      setErrorMessage('');
                      resetErrorStore();
                      setLoginForm(!loginForm);
                    }}
                    role="link">
                    {loginForm ? 'Forgot Password?' : 'back to login'}
                  </Link>
                </div>
              </form>
            )}
            {success && (
              <TextBlock
                className="my-6 text-center"
                size="md">
                Password reset email sent.
              </TextBlock>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
