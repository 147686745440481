import { useState, useEffect, FormEventHandler, FormEvent } from 'react';
import { PageLayout } from '../../PageLayout';
import { Tab } from '@headlessui/react';
import { PortalUserData } from '../../../types';
import { isTeamManager, getUser, isAdmin, isSupport } from '../../../store/User';
import { getPortalUserSubject, usePortalUsers } from '../../../store/PortalUser';
import { TeamTab } from './Tabs/TeamTab';
import { Team, getTeamsSubject, useTeamsStore } from '../../../store/Team';
import { useAccountCustomizationsStore } from '../../../store/AccountCustomization';
import { useRoles } from '../../../store/Role';
import { buildAccountData, useAccounts } from '../../../store/Accounts';
import { UserTab } from './Tabs/UserTab';
import { AccountsTab } from './Tabs/AccountsTab';
import { getGeneralStoreSubject, updateState } from '../../../store/GeneralStore';
import { getTabListItemClassName } from '../../../utils/helpers';
import { ComponentSpinner } from '../../Compounds/Loading/ComponentSpinner';

type TabChangeHandler = (index: number) => void;

const getTabPanelClassName = (index: number, selectedIndex: number): string => {
  return index === selectedIndex ? 'block' : 'hidden';
};

export const AdminPage = () => {
  const user = getUser();
  const page = isAdmin(user) || isSupport(user) ? 'Admin' : 'Team Settings';
  const { getPortalUsers } = usePortalUsers();
  const { fetchTeams } = useTeamsStore();
  const { fetchRoles } = useRoles();
  const { fetchAccounts } = useAccounts();
  const [portalUsers, setPortalUsers] = useState<PortalUserData[]>([]);
  const [teams, setTeams] = useState<Team[]>([]);
  const { fetchAccountCustomizations } = useAccountCustomizationsStore();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [adminLoading, setAdminLoading] = useState(false);

  const tabs = isTeamManager(user)
    ? [{ name: 'Users', component: <UserTab portalUsers={portalUsers} /> }]
    : [
        { name: 'Users', component: <UserTab portalUsers={portalUsers} /> },
        { name: 'Teams', component: <TeamTab teams={teams} /> },
        {
          name: 'Accounts',
          component: <AccountsTab />
        }
      ];

  const initAdminData = async () => {
    setAdminLoading(true);
    await Promise.all([fetchAccounts(), fetchRoles(), fetchAccountCustomizations(), getPortalUsers()]);

    // Account data depends on accounts, customizations, and portal users.
    buildAccountData();

    // Wait to fetch teams, depends on accounts.
    await fetchTeams();
    setAdminLoading(false);
  };

  useEffect(() => {
    const portalUsersSub = getPortalUserSubject().subscribe((portalUsersData) => setPortalUsers(portalUsersData));
    const teamsSub = getTeamsSubject().subscribe((teams) => setTeams(teams));
    const generalStateSub = getGeneralStoreSubject().subscribe(({ admin }) => {
      setSelectedIndex(admin.selectedTab);
    });

    initAdminData();

    return () => {
      if (portalUsersSub) portalUsersSub.unsubscribe();
      if (teamsSub) teamsSub.unsubscribe();
      if (generalStateSub) generalStateSub.unsubscribe();
    };
  }, []);

  const handleTabChange: TabChangeHandler & FormEventHandler<HTMLDivElement> = (
    eventOrIndex: FormEvent<HTMLDivElement> | number
  ) => {
    if (typeof eventOrIndex === 'number') {
      updateState({ admin: { selectedTab: eventOrIndex } });
    }
  };

  return (
    <PageLayout
      dataCy="admin-page-card"
      pageTitle={page}>
      <Tab.Group
        selectedIndex={selectedIndex}
        as="div"
        onChange={handleTabChange}>
        <Tab.List className="border-b border-t bg-grey-1 border-gray-200 mb-2 mt-6">
          {tabs?.map(({ name }, index) => (
            <Tab
              data-cy={`tab-link-${name}`}
              key={name}
              className={getTabListItemClassName(index, selectedIndex)}>
              <span>{name}</span>
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="bg-white p-3">
          {adminLoading && <ComponentSpinner sx={{ marginTop: '15rem' }} />}
          {!adminLoading &&
            tabs?.map(({ name, component }, index) => (
              <Tab.Panel
                key={name}
                className={getTabPanelClassName(index, selectedIndex)}>
                {component}
              </Tab.Panel>
            ))}
        </Tab.Panels>
      </Tab.Group>
    </PageLayout>
  );
};
