import { FunctionComponent, useEffect, useState } from 'react';
import { isAdvisor, getUser, isAdmin, isSupport, isExecutive, isOnlyCommissionsRole } from '../../../store/User';
import { AdminPermission, Permission, SupportPermission } from '../../../store/GeneralStore';
import { ContactUsLink } from '../ContactUs/ContactUsLink';
import { AuthenticatedUser } from '../../../types';
import { AdminPageLink } from '../AdminPageLink';
import { TeamSettingsPageLink } from '../TeamSettingsPageLink';
import { PermissibleRender } from '../PermissibleRender';
import { TextColor, TextSpan } from '../../Atoms/Text';
import DropdownMenu from '../Menu/DropdownMenu';
import { useSession } from '../../../Api/useSession';
import { AccountDropdown } from './AccountDropdown';
import { NotificationCenter } from '../Notifications/NotificationCenter';
import { FeatureFlags, defaultFeatureFlags, getFeatureFlagSubject } from '../../../Api/useFeatureFlags';
import { useNavigate } from 'react-router-dom';
import { IconType } from '../../Atoms/Icon';

const AccountScopeHeader = ({ user }: { user: AuthenticatedUser }) => {
  const showAccountSelect = [
    isAdvisor(user),
    isExecutive(user),
    isAdmin(user),
    isSupport(user),
    user?.accounts?.length > 1
  ].some((r) => r);

  return (
    <>
      {showAccountSelect && (
        <div
          className="flex items-center space-x-2"
          data-testid="account-select">
          <TextSpan className="text-[.90rem]">Account(s)</TextSpan>
          <AccountDropdown />
        </div>
      )}
      {!showAccountSelect && <>{user.account_name}</>}
    </>
  );
};

export const NavBarTabs: FunctionComponent = () => {
  const { clearSession } = useSession();
  const user = getUser();
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>(defaultFeatureFlags);
  const navigate = useNavigate();

  useEffect(() => {
    const flags = getFeatureFlagSubject().subscribe((flags) => setFeatureFlags(flags));

    return () => {
      if (flags) flags?.unsubscribe();
    };
  }, []);

  if (!user.authenticated) return <div className="grow flex items-center" />;

  const menuOptions = [];
  if (!isOnlyCommissionsRole(user)) {
    menuOptions.push({
      title: 'Settings',
      onClick: () => navigate('/user-settings'),
      fontColor: 'grey6' as TextColor,
      icon: 'settings' as IconType
    });
  }
  menuOptions.push({
    title: 'Log Out',
    onClick: () => clearSession(),
    fontColor: 'grey6' as TextColor,
    icon: 'logoutSmall' as IconType
  });

  const permission: AdminPermission.VIEW_ADMIN | SupportPermission.VIEW_SUPPORT = isSupport(user)
    ? Permission.VIEW_SUPPORT
    : Permission.VIEW_ADMIN;

  const isAccountHeaderHidden = ['admin', 'commissions'].some((p) => window.location.pathname.includes(p));

  return (
    <div className="flex w-full items-center">
      {!isAccountHeaderHidden && <AccountScopeHeader user={user} />}
      <div className="flex ml-auto justify-end space-x-3 items-center">
        <PermissibleRender requiredPermissions={[permission]}>
          <AdminPageLink />
        </PermissibleRender>
        <PermissibleRender requiredPermissions={[Permission.VIEW_TEAM]}>
          <TeamSettingsPageLink />
        </PermissibleRender>
        {!isOnlyCommissionsRole(user) && <ContactUsLink linkText="Contact" />}
        {!isOnlyCommissionsRole(user) && featureFlags.notifications && <NotificationCenter />}
        <DropdownMenu
          icon="account"
          buttonText="Account"
          options={menuOptions}
          slotProps={{
            paper: {
              style: {
                width: 150
              }
            }
          }}
        />
      </div>
    </div>
  );
};
