import { useState } from 'react';
import { TextSpan, TextBlock } from '../../Atoms/Text';
import { useForm } from 'react-hook-form';
import { ContactRequest, postContactRequest } from '../../../Api/ContactUs/postContactRequest';
import { Icon } from '../../Atoms/Icon';
import { getAccounts, getAccountScope } from '../../../store/User';
import { useSnackbar } from '../../../Context/SnackbarContext';
import {
  DEFAULT_ADVANCE_BUTTON_STYLE,
  DEFAULT_CANCEL_BUTTON_STYLE,
  SELECT_INPUT_STYLE
} from '../../../utils/styleHelpers';
import { Account } from '../../../types';
import { AccountSelectionForm } from '../UserCreation/AccountSelectionForm';

export interface FormData {
  subject: string;
  message: string;
}

const picklistValues: string[] = ['Report an issue', 'Provide feedback', 'Other'];

const validateDescription = (value: string) => {
  if (value == 'required') return 'Please enter a detailed description of your issue';

  if (value == 'maxLength') return 'Character count is limited to 2,000';

  return true;
};

export interface ContactUsFormProps {
  onClose: () => void;
}

export function ContactUsForm({ onClose }: ContactUsFormProps) {
  const { register, handleSubmit, formState } = useForm<FormData>();
  const [statusMessage, setStatusMessage] = useState('');
  const accounts = getAccounts();
  const [selectedAccount, setSelectedAccount] = useState<Account | undefined>(
    getAccountScope()?.length === 1 ? getAccountScope()?.[0] : undefined
  );

  const { setSnack } = useSnackbar();
  const { errors } = formState;

  async function handlePostContactRequest(requestBody: ContactRequest) {
    const resp = await postContactRequest(requestBody, selectedAccount?.id);
    if (resp.data) {
      setStatusMessage('success');
      onClose();
      setSnack({ message: 'Your email has been sent successfully.', type: 'success', open: true });
    } else {
      setStatusMessage('errors');
      setSnack({ message: 'There was an error sending your request.', type: 'error', open: true });
    }
  }

  const handleSubmitForm = (formData: FormData): void => {
    setStatusMessage('inProgress');
    const requestBody: ContactRequest = {
      subject: formData.subject,
      message: formData.message
    };
    handlePostContactRequest(requestBody);
  };

  const disabled = statusMessage == 'inProgress';

  return (
    <>
      <TextBlock className="text-xl mb-2">Contact</TextBlock>
      {statusMessage == 'errors' && [
        <TextSpan
          className="py-2 text-[12px] px-1 bg-yellow flex block"
          size="sm"
          weight="light"
          color="grey8"
          key="alert-message">
          <Icon
            className="p-2.5 m-2.5 h-5 w-5"
            type="alert"
          />
          Something went wrong on our end. &nbsp;
          <br />
          Please try again.
        </TextSpan>,
        <TextSpan
          className="py-2 text-[12px] block"
          size="sm"
          weight="light"
          color="grey8"
          key="alert-notice">
          If you continue to have trouble, please &nbsp;
          <br />
          contact us at &nbsp;
          <a
            className="underline"
            href="mailto:support@upstack.com">
            support@upstack.com
          </a>
        </TextSpan>
      ]}
      <TextSpan
        className="py-2 text-[13px] block"
        color="grey7"
        size="sm14">
        Need help with the portal, or want to provide feedback? Contact your support staff
      </TextSpan>
      <form
        data-cy="contact-us"
        aria-label="Contact Us"
        onSubmit={handleSubmit(handleSubmitForm)}>
        <div className="form-group">
          <AccountSelectionForm
            accounts={accounts || []}
            setSelectedAccount={(v) => setSelectedAccount(v)}
            selectedAccount={selectedAccount || null}
            styles="flex items-center w-54 pb-4"
            showLabel={false}
            inputStyles="h-[1.75rem]"
          />
          <select
            className={`${SELECT_INPUT_STYLE} ${errors.subject && 'border border-[red]'}`}
            data-cy="subject"
            role="combobox"
            aria-label="subject"
            id="subject"
            {...register('subject', { required: true })}>
            <option value="">Subject</option>
            {picklistValues.map((value) => (
              <option
                key={value}
                value={value}>
                {' '}
                {value}{' '}
              </option>
            ))}
          </select>
          {errors.subject && (
            <div
              className="invalid-feedback m-0"
              data-cy="invalid-feedback">
              This field is required
            </div>
          )}
        </div>
        <div className="form-group">
          <textarea
            className={`${SELECT_INPUT_STYLE} ${errors.message && 'border border-[red]'} max-h-fit min-h-fit`}
            data-cy="message"
            id="message"
            {...register('message', {
              required: true,
              maxLength: 2000,
              validate: validateDescription
            })}
            rows={13}
            placeholder="Message"
          />
          {errors.message && (
            <div
              className="invalid-feedback m-0"
              data-cy="invalid-feedback">
              {validateDescription(errors.message.type)}
            </div>
          )}
        </div>
        <hr className="mt-3 mb-5" />
        <div className="flex justify-between">
          <button
            className={DEFAULT_CANCEL_BUTTON_STYLE}
            disabled={disabled}
            onClick={onClose}
            type="button">
            Cancel
          </button>
          <button
            type="submit"
            disabled={disabled}
            className={DEFAULT_ADVANCE_BUTTON_STYLE}>
            Send
          </button>
        </div>
      </form>
    </>
  );
}
