import { useEffect, useState } from 'react';
import {
  AppConfigDataClient,
  GetLatestConfigurationCommand,
  StartConfigurationSessionCommand
} from '@aws-sdk/client-appconfigdata';
import { BehaviorSubject } from 'rxjs';
import { datadogLogs } from '@datadog/browser-logs';

export interface UseFeatureFlagsReturn {
  config: FeatureFlags;
  errorMessage: string | undefined;
}
export interface FeatureFlags {
  maintenance_mode: boolean;
  notifications: boolean;
  outside_inventory: boolean;
  outside_inventory_bulk: boolean;
  top_suppliers_widget: boolean;
  comments: boolean;
  ticket_wizard: boolean;
  my_views: boolean;
}
export const defaultFeatureFlags: FeatureFlags = {
  maintenance_mode: false,
  notifications: true,
  outside_inventory: false,
  outside_inventory_bulk: false,
  top_suppliers_widget: false,
  comments: false,
  ticket_wizard: false,
  my_views: false
};
const defaultErrorMessage = undefined;

const subject = new BehaviorSubject<FeatureFlags>(defaultFeatureFlags);
const errorSubject = new BehaviorSubject<string | undefined>(defaultErrorMessage);

export const getFeatureFlagErrorSubject = (): BehaviorSubject<string | undefined> => errorSubject;
export const getFeatureFlagSubject = (): BehaviorSubject<FeatureFlags> => subject;

export const useFeatureFlags = (): UseFeatureFlagsReturn => {
  const [config, setConfig] = useState<FeatureFlags>(defaultFeatureFlags);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(defaultErrorMessage);

  const client = new AppConfigDataClient({
    region: process.env.REACT_APP_AWS_REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID ?? '',
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY ?? ''
    }
  });

  const initConfig = async () => {
    try {
      const startCommand = new StartConfigurationSessionCommand({
        ApplicationIdentifier: process.env.REACT_APP_APPCONFIG_APPLICATION_ID,
        EnvironmentIdentifier: process.env.REACT_APP_APPCONFIG_ENVIRONMENT_ID,
        ConfigurationProfileIdentifier: process.env.REACT_APP_APPCONFIG_CONFIGURATION_PROFILE_ID
      });

      const { InitialConfigurationToken } = await client.send(startCommand);
      fetchConfig(InitialConfigurationToken);
    } catch (error) {
      setConfigData(defaultFeatureFlags);
      datadogLogs.logger.error('Error in init AppConfig: ', error as Error);
    }
  };

  const fetchConfig = async (token?: string) => {
    try {
      const fetchCommand = new GetLatestConfigurationCommand({ ConfigurationToken: token });
      const {
        NextPollConfigurationToken,
        Configuration,
        NextPollIntervalInSeconds: pollInt
      } = await client.send(fetchCommand);

      if (Configuration?.length) {
        const configData: FeatureFlags = JSON.parse(new TextDecoder().decode(Configuration));
        setConfigData(configData);
      }

      setTimeout(() => fetchConfig(NextPollConfigurationToken), pollInt ? pollInt * 1000 : 60000);
    } catch (error) {
      setConfigData(defaultFeatureFlags);
      datadogLogs.logger.error('Error fetchConfig: ', error as Error);
    }
  };

  useEffect(() => {
    initConfig();

    const subscription = subject.subscribe((data) => {
      setConfig(data);
    });
    const errorSubscription = errorSubject.subscribe((message) => {
      setErrorMessage(message);
    });

    return () => {
      if (subscription) subscription.unsubscribe();
      if (errorSubscription) errorSubscription.unsubscribe();
    };
  }, []);

  const setConfigData = (configData: FeatureFlags) => subject.next(configData);

  return { config, errorMessage };
};
