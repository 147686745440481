import { PageLayout } from '../../PageLayout';
import { TextSpan } from '../../Atoms/Text';
import { Card } from '../../Atoms/Card';
import { useGeneralStore } from '../../../store/GeneralStore';
import { fetchMyTeam } from '../../../Api/MyTeam/fetchMyTeam';
import { useEffect, useState } from 'react';
import { useFetching } from '../../../Context/FetchingContext';
import { objectNotEmpty } from '../../../utils/helpers';
import { ContactUsLink } from '../../Compounds/ContactUs/ContactUsLink';
import { Account, TeamMember } from '../../../types';
import { Session, getSessionSubject } from '../../../Api/useSession';
import { ComponentSpinner } from '../../Compounds/Loading/ComponentSpinner';
import { addError } from '../../../store/Error';
import { AccountSelectionForm } from '../../Compounds/UserCreation/AccountSelectionForm';
import { getAccounts, getAccountScope } from '../../../store/User';
const DEFAULT_AVATAR_PATH = '/my-team-default-avatar.png';

export const MyTeamPage = () => {
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
  const {
    currentState: { myTeam },
    updateState,
    setErrorMessageData
  } = useGeneralStore();
  const { setFetching, isFetching } = useFetching();
  const { owner, success_manager: successManager } = myTeam;
  const accounts = getAccounts();
  const [session, setSession] = useState<Session | undefined>(undefined);
  const [selectedAccount, setSelectedAccount] = useState<Account | undefined>(
    getAccountScope()?.length === 1 ? getAccountScope()?.[0] : undefined
  );

  const emitErrorState = (message?: string): void => addError('my-team-page', message);

  const getData = async () => {
    setFetching(true);

    const { data, error } = await fetchMyTeam(selectedAccount?.id);

    if (error) {
      setErrorMsg(error?.message);
      setErrorMessageData(error?.message);
    }
    if (data) updateState({ myTeam: data });

    setFetching(false);
  };

  useEffect(() => {
    if (selectedAccount?.id && session?.token) getData();
  }, [selectedAccount?.id, session?.token]);

  useEffect(() => {
    const sessionSub = getSessionSubject().subscribe((session) => setSession(session));

    return () => {
      if (sessionSub) sessionSub.unsubscribe();
    };
  }, []);

  if (errorMsg) {
    emitErrorState(errorMsg);
  }

  const pageActions = () => {
    return (
      <AccountSelectionForm
        accounts={accounts || []}
        disabled={accounts?.length === 1}
        setSelectedAccount={(v) => setSelectedAccount(v)}
        selectedAccount={selectedAccount || null}
        styles="flex items-center w-72 pb-4 mr-auto"
        showLabel={true}
        inputStyles="h-[1.75rem]"
      />
    );
  };

  return (
    <PageLayout
      pageTitle="My Team"
      pageActions={pageActions()}>
      {!isFetching && !selectedAccount && (
        <div className="w-full flex justify-center items-center mt-20">
          <TextSpan
            size="md"
            className="self-center">
            Please select an account to view team data.
          </TextSpan>
        </div>
      )}
      {isFetching && (
        <div className="flex mt-40">
          <ComponentSpinner label="Loading Data" />
        </div>
      )}
      {!isFetching && selectedAccount && (
        <>
          <div className="flex mt-10">
            <TextSpan
              className="text-xl font-medium"
              dataTestId="my-team-subhead">
              Need to touch base on your services?
            </TextSpan>
          </div>
          <div className="grid grid-cols-1 xl:grid-cols-2 mt-8 gap-8 max-w-6xl">
            <TeamMemberCard
              isAdvisor={true}
              member={owner}
            />
            {successManager.email && (
              <TeamMemberCard
                isAdvisor={false}
                member={successManager}
              />
            )}
          </div>
          <div>
            <hr className="mt-6 mb-3" />
            <TextSpan>Need help with the portal, or want to provide feedback?</TextSpan>
            <ContactUsLink
              dataTestId="my-team-support"
              linkText="Contact your support staff"
              textStyle="text-sm"
            />
          </div>
        </>
      )}
    </PageLayout>
  );
};

interface TeamMemberCardProps {
  isAdvisor: boolean;
  member?: TeamMember;
}

const TeamMemberCard = ({ isAdvisor, member }: TeamMemberCardProps) => {
  if (!objectNotEmpty(member)) return null;

  const { id, firstName, lastName, email, phone } = member;
  const teamMemberType = isAdvisor ? 'Advisor' : 'Customer Success Manager';
  const name = `${firstName} ${lastName}`;
  return (
    <Card className="flex max-w-xl">
      <img
        alt={`${teamMemberType} ${name}`}
        className="ml-[3.5%] self-center h-4/5 aspect-square"
        onError={(e) => (e.currentTarget.src = DEFAULT_AVATAR_PATH)}
        src={`${process.env.REACT_APP_API_SERVER_URL}/api/user/my-team/avatar/${id}`}
      />
      <div className="m-5 ml-[7%]">
        <TextSpan
          className="text-xl font-medium w-4/5 block mb-5"
          dataTestId={teamMemberType}>
          {`Your ${teamMemberType}:`}
        </TextSpan>
        <TextSpan
          className="text-md font-medium block"
          dataTestId={`${teamMemberType} name`}>
          {name}
        </TextSpan>
        <TextSpan
          className="text-md font-medium block break-all"
          dataTestId={`${teamMemberType} email`}>
          {email}
        </TextSpan>
        {!!phone && (
          <TextSpan
            className="text-md font-medium block"
            dataTestId={`${teamMemberType} phone`}>
            {phone}
          </TextSpan>
        )}
      </div>
    </Card>
  );
};
