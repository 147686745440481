import { Notification, updateNotification, NotificationType } from '../../../store/Notifications';
import { Icon } from '../../Atoms/Icon';
import { Text, TextBlock } from '../../Atoms/Text';
import { InstallUpdate } from './Body/InstallUpdate';
import LinkIcon from '@mui/icons-material/Link';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { SupportTicketNotification } from './Body/SupportTicket';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { CommentMentionNotification } from './Body/CommentMentionNotification';
import { InventoryAsset, SupportTicketsItem } from '../../../types';
import { Comment } from '../../../store/Comment';
import { CommentCreatedNotification } from './Body/CommentCreatedNotification';

const getCommentUrlPath = (notification: Notification) => {
  const entity = notification.notification_message.content?.entity as Comment;
  return entity?.entity_type?.includes('Inventory') ? 'inventory' : 'orders';
};

const getCommentUrlId = (notification: Notification) => {
  return (notification?.notification_message?.content?.entity as Comment)?.entity_id;
};

export const NotificationItem = ({
  notification,
  handleClose
}: {
  notification: Notification;
  handleClose: () => void;
}) => {
  const navigate = useNavigate();

  const notifyConfig = {
    [NotificationType.INSTALL_UPDATE]: {
      component: <InstallUpdate notification={notification} />,
      titleField: 'address',
      link: `/inventory?detailId=${notification?.entity_id}`
    },
    [NotificationType.TICKET_OPEN]: {
      component: <SupportTicketNotification notification={notification} />,
      titleField: 'address',
      link: `/support-ticketing?detailId=${notification?.entity_id}`
    },
    [NotificationType.TICKET_CLOSED]: {
      component: <SupportTicketNotification notification={notification} />,
      titleField: 'address',
      link: `/support-ticketing?detailId=${notification?.entity_id}`
    },
    [NotificationType.COMMENT_MENTION]: {
      component: <CommentMentionNotification notification={notification} />,
      titleField: 'address',
      link: `/${getCommentUrlPath(notification)}?detailId=${getCommentUrlId(notification)}`
    },
    [NotificationType.COMMENT_CREATED]: {
      component: <CommentCreatedNotification notification={notification} />,
      titleField: 'address',
      link: `/${getCommentUrlPath(notification)}?detailId=${getCommentUrlId(notification)}`
    }
  };
  const notifyMeta =
    notifyConfig[notification?.notification_message?.message_type as keyof typeof notifyConfig] ||
    notifyConfig['Install Update'];

  const getTitleText = (notification: Notification, field = 'address') => {
    let value = '';
    const entity = notification?.notification_message?.content?.entity;
    switch (field) {
      case 'address':
        value =
          (entity as InventoryAsset | SupportTicketsItem)?.address ||
          (entity as Comment)?.entity_name ||
          'Data missing';
        break;
      default:
        break;
    }
    return (
      <div
        className="ml-6"
        title={value}>
        <Text
          size="sm"
          color="grey5">
          {`${value?.length > 25 ? `${value?.substring(0, 25)}...` : value}`}
        </Text>
      </div>
    );
  };

  return (
    <li
      className="border-t-2"
      key={notification.id}
      style={{ display: 'grid', whiteSpace: 'normal' }}>
      <div className="px-4 py-3">
        <div className="flex grow w-full items-center">
          <Icon type={!notification.is_read ? 'notificationsDot' : 'notificationsReadDot'} />
          {getTitleText(notification, notifyMeta?.titleField)}
          <div className="flex ml-auto space-x-1">
            <IconButton
              data-cy="notification-entity"
              title="Navigate to entity"
              onClick={() => {
                handleClose();
                navigate(notifyMeta?.link);
              }}>
              <LinkIcon />
            </IconButton>
            <Icon
              title="Dismiss notification"
              className="p-1 hover:cursor-pointer"
              type="cancel"
              onClick={() =>
                updateNotification({
                  ...notification,
                  is_archived: true,
                  is_read: true
                })
              }
            />
          </div>
        </div>
        {notifyMeta.component}
        {notification.created_at && (
          <TextBlock
            className="ml-9 mt-2"
            size="sm"
            color="grey4">
            {formatDistanceToNow(parseISO(notification.created_at))} ago
          </TextBlock>
        )}
      </div>
    </li>
  );
};
