import React, { useState } from 'react';
import { WidgetProps } from '@rjsf/utils';
import { TextField, Autocomplete } from '@mui/material';
import { INPUT_STYLE } from '../../../../../utils/styleHelpers';
import { DetailRow } from '../../../DetailsDrawer/DetailRow';

export const TermSelectionInput = (props: WidgetProps) => {
  const { value, onChange } = props;
  const [searchTerm, setSearchTerm] = useState(value || '');
  const [dirty, setDirty] = useState(false);
  const [selectedTerm, setSelectedTerm] = useState<string | null>(null);
  const terms = ['Month to Month', '12', '24', '36'];

  const handleChange = (e: React.SyntheticEvent<Element, Event>, value: string | null) => {
    setSelectedTerm(value || null);
    setSearchTerm(value || '');
    onChange(value || null);
  };

  const handleInputChange = (e: React.SyntheticEvent<Element, Event>, value: string | null) => {
    if (!dirty) setDirty(true);
    setSelectedTerm(value || null);
    setSearchTerm(value);
    onChange(value || null);
  };

  const getHelperText = () => {
    if (isErrorState()) {
      if (props.rawErrors?.includes('must be string')) return 'Term is required';
      if (props.rawErrors?.some((e) => e.includes('must match pattern'))) {
        if (value === '' || value === undefined) return 'Term is required';
        return 'Term must be alphanumeric, please remove special characters';
      }
    }
  };

  const isErrorState = () => {
    return dirty && ((props.rawErrors && props.rawErrors.length > 0) || (!value && props.required));
  };

  if (props.readonly) {
    if (props.formContext?.hideEmpty && !value) return null;

    return (
      <div>
        <DetailRow
          key={props.id}
          colId={props.id}
          title={props.uiSchema?.['ui:placeholder'] || ''}
          value={value || ''}
        />
      </div>
    );
  }

  return (
    <Autocomplete
      value={selectedTerm || searchTerm}
      inputValue={searchTerm}
      onInputChange={handleInputChange}
      ListboxProps={{ style: { maxHeight: 150 } }}
      onChange={handleChange}
      onBlur={() => setDirty(true)}
      onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
      options={terms}
      getOptionLabel={(option) => option}
      freeSolo={true}
      renderInput={(params) => (
        <TextField
          {...params}
          required={props.required}
          error={isErrorState()}
          helperText={getHelperText()}
          label={props.uiSchema?.['ui:placeholder']}
          InputProps={{
            ...params.InputProps,
            style: { fontSize: '.875rem' },
            name: props.uiSchema?.['ui:placeholder']
          }}
          className={INPUT_STYLE}
        />
      )}
    />
  );
};

export default TermSelectionInput;
