import { useEffect, useRef, useState } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { DocumentPreview } from './DocumentPreviewTool';
import { getAttachmentPublicUrl } from '../../Api/Attachments/attachmentsApi';
import { ComponentSpinner } from '../Compounds/Loading/ComponentSpinner';
import { TextBlock } from './Text';
import { S3Attachment } from '../../types';
import { getAttachments } from '../../store/S3Attachment';

const defaultCenter = {
  lat: 42,
  lng: -100
};

export interface MapDocPreviewerProps {
  file: DocumentPreview;
}

export const MapDocPreviewer = ({ file }: MapDocPreviewerProps) => {
  const mapRef = useRef<google.maps.Map | null>(null);
  const [center, setCenter] = useState(defaultCenter);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [fileLoading, setFileLoading] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''
  });

  const handleMapLoad = (mapInstance: google.maps.Map) => {
    mapRef.current = mapInstance;
  };

  const renderErrorElement = () => (
    <>
      <TextBlock
        size="xs"
        className="cursor-default">
        Error rendering {file.fileName}
      </TextBlock>
      <TextBlock
        size="xs"
        weight="thin"
        className="cursor-default">
        Unable to preview map files &gt;5MB
      </TextBlock>
    </>
  );

  useEffect(() => {
    const fetchFileUrl = async () => {
      const { data, error } = await getAttachmentPublicUrl(file.id, file.fileName);

      if (data) setFileUrl(data.url);
      if (error) setErrorState(true);
      setFileLoading(false);
    };

    const fetchS3Url = () => {
      const data = getAttachments().find((att: S3Attachment) => att.id === file.id);

      if (data && data.presigned_url) setFileUrl(data.presigned_url);

      // This is a hack to fix the rendering issue with google maps KML layer
      setTimeout(() => {
        setFileLoading(false);
      }, 10);
    };

    setErrorState(false);
    setFileLoading(true);

    if (file.isS3) {
      fetchS3Url();
    } else {
      fetchFileUrl();
    }
  }, []);

  useEffect(() => {
    if (mapRef && mapRef.current && fileUrl) {
      setErrorState(false);
      const kmlLayer = new window.google.maps.KmlLayer({
        url: fileUrl,
        map: mapRef.current,
        preserveViewport: false
      });

      window.google.maps.event.addListener(kmlLayer, 'defaultviewport_changed', () => {
        const bounds = kmlLayer.getDefaultViewport();
        if (bounds && mapRef.current) {
          const center = bounds.getCenter();
          setCenter({ lat: center.lat(), lng: center.lng() });
          mapRef.current.fitBounds(bounds);
        }
      });

      window.google.maps.event.addListener(kmlLayer, 'status_changed', () => {
        if (kmlLayer.getStatus() !== 'OK') {
          setErrorState(true);
        }
      });
    }
  }, [mapRef.current, fileUrl]);

  if (!isLoaded)
    return (
      <div
        className="p-2 h-[96%] w-[100%]"
        data-testid="map-doc-previewer">
        <TextBlock
          size="xs"
          weight="thin"
          className="cursor-default">
          Map previewer unavailable
        </TextBlock>
      </div>
    );

  return (
    <div
      className="p-2 h-[96%] w-[100%]"
      data-testid="map-doc-previewer">
      {fileLoading && <ComponentSpinner />}
      {errorState && !fileLoading && renderErrorElement()}
      <div className={`h-full w-full ${fileLoading || errorState ? 'invisible' : 'visible'}`}>
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '100%' }}
          options={{
            fullscreenControl: false,
            mapTypeControl: false,
            streetViewControl: false,
            styles: require('../Compounds/MapWidget/mapStyles.json'),
            zoomControl: false
          }}
          center={center}
          onLoad={handleMapLoad}
          mapContainerClassName="rounded-lg"
          zoom={3}
        />
      </div>
    </div>
  );
};
