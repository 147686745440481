import { Text } from '../../Atoms/Text';
import { Icon } from '../../Atoms/Icon';
import { SideBarItem } from './SideBarItem';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { getSelectedAccountsSubject, getUserSubject, isOnlyCommissionsRole } from '../../../store/User';
import { HIDE_NAV_PAGES } from '../../../utils/helpers';
import { Account, User } from '../../../types';
import { useSession } from '../../../Api/useSession';
import { PermissibleRender } from '../PermissibleRender';
import { Permission } from '../../../store/GeneralStore';

export const SideNavBar = () => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [showSideBar, setShowSideBar] = useState<boolean>(false);
  const [selectedAccounts, setSelectedAccounts] = useState<Account[] | null>();
  const location = useLocation();
  const name = location.pathname.replace('/', '');
  const [user, setUser] = useState<User | undefined>(undefined);
  const { clearSession } = useSession();

  useEffect(() => {
    if (user) {
      setShowSideBar(!HIDE_NAV_PAGES.includes(name));
    }
  }, [user, name, selectedAccounts?.length]);

  useEffect(() => {
    const userSub = getUserSubject().subscribe((user) => setUser(user));
    const acctSub = getSelectedAccountsSubject().subscribe((accts) => {
      setSelectedAccounts(accts);
    });

    return () => {
      if (userSub) userSub.unsubscribe();
      if (acctSub) acctSub.unsubscribe();
    };
  }, []);

  const changeExpanded = (): void => setExpanded(!expanded);
  const handleLogOut = () => clearSession();

  if (!showSideBar) return <></>;

  return (
    <>
      <div className="sidebar-wrapper z-10 max-h-0">
        <div
          className={classNames('sidebar min-h-screen border-r hover:shadow-lg min-w bg-sky-50', {
            'w-[3.35rem]': !expanded,
            'w-48': expanded
          })}
          data-testid="sidebar">
          <div className="flex h-screen flex-col justify-between pb-6">
            <div>
              <div className="py-2 pl-3.5 border-b w-full overflow-hidden relative">
                <Link
                  className="ml-auto min-w-[10rem] block with-rectangle after:bg-sky-50 after:w-3"
                  title="Home"
                  to={'/'}>
                  <img
                    src={'/UPSTACK-Logo.svg'}
                    className="mr-3 h-10"
                    alt="UPSTACK Logo"
                  />
                </Link>
              </div>
              <div className="m-1 p-2 text-gray-500 text-sm font-normal">APPS</div>
              <ul className="space-y-2 tracking-wide">
                {!isOnlyCommissionsRole(user) && [
                  <SideBarItem
                    navLinkProps={{ to: '' }}
                    iconProps={{ type: 'homeIcon' }}
                    textProps={{ children: 'Home' }}
                    active={name === ''}
                    dataCy="home-sidebar"
                    key="home-sidebar"
                  />,
                  <SideBarItem
                    navLinkProps={{ to: 'orders' }}
                    iconProps={{ type: 'ordersIcon' }}
                    textProps={{ children: 'Orders' }}
                    active={name === 'orders'}
                    dataCy="orders-sidebar"
                    key="orders-sidebar"
                  />,
                  <PermissibleRender
                    key="support-ticketing-sidebar"
                    requiredPermissions={[Permission.VIEW_TICKETS]}>
                    <SideBarItem
                      navLinkProps={{ to: 'support-ticketing' }}
                      iconProps={{ type: 'ticketsIcon' }}
                      textProps={{ children: 'Support Tickets' }}
                      active={name === 'support-ticketing'}
                      dataCy="support-ticketing-sidebar"
                    />
                  </PermissibleRender>,
                  <SideBarItem
                    navLinkProps={{ to: 'inventory' }}
                    iconProps={{ type: 'inventoryIcon' }}
                    textProps={{ children: 'Inventory' }}
                    active={name === 'inventory'}
                    dataCy="inventory-sidebar"
                    key="inventory-sidebar"
                  />
                ]}
                <PermissibleRender requiredPermissions={[Permission.VIEW_COMMISSION_SYNC]}>
                  <SideBarItem
                    navLinkProps={{ to: 'commissions' }}
                    iconProps={{ type: 'commissionsIcon' }}
                    textProps={{ children: 'Commissions' }}
                    active={name === 'commissions'}
                    dataCy="commissions-sidebar"
                  />
                </PermissibleRender>
                <div className="-mb-5 border-t w-full flex"></div>
                {!isOnlyCommissionsRole(user) && (
                  <SideBarItem
                    navLinkProps={{ to: 'my-team' }}
                    iconProps={{ type: 'myTeam' }}
                    textProps={{ children: 'My Team' }}
                    active={name === 'my-team'}
                    dataCy="my-team-sidebar"
                  />
                )}
              </ul>
            </div>
            <div className="-mb-5 border-t w-full flex">
              <button
                className="bg group flex items-center space-x-4 m-2 p-2 pl-1 text-gray-600 hover:bg-sky-100 w-full overflow-hidden whitespace-nowrap hover:min-w-max rounded"
                onClick={handleLogOut}>
                <Icon type="logoutIcon" />
                <Text
                  dataTestId="logout-button"
                  className="group-hover:text-gray-700"
                  color="black"
                  size="base"
                  weight="normal">
                  Log Out
                </Text>
              </button>
            </div>
          </div>
          <button
            className={classNames('expand-button arrow-sidebar z-10', { 'reverse-button': expanded })}
            onClick={changeExpanded}
          />
        </div>
        <button
          aria-label="expand-button-external"
          className={classNames('expand-button arrow-sidebar', { 'reverse-button': expanded })}
          onClick={changeExpanded}
        />
      </div>
    </>
  );
};
