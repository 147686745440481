import { useEffect, useState } from 'react';
import { SupportTicketsItem } from '../../../types';
import { PageLayout } from '../../PageLayout';
import { PageCard } from '../../Compounds/CardWithTitle';
import { useSearchParams } from 'react-router-dom';
import { DetailsDrawer } from '../../Compounds/DetailsDrawer';
import { getMetaData } from '../../Compounds/DetailsDrawer/detailsDrawersHelpers';
import { SupportTicketWizard } from '../../Compounds/Wizards/SupportTicketWizard';
import {
  getTicketLoadingSubject,
  getTickets,
  getTicketSubject,
  OPEN_STATUS,
  useSupportTickets
} from '../../../store/SupportTicket';
import { DashboardWidgets } from '../../Compounds/DashboardWidgets/DashboardWidgets';
import { PermissibleRender } from '../../Compounds/PermissibleRender';
import { Permission } from '../../../store/GeneralStore';
import {
  GridPreferences,
  getDefaultPreferences,
  getPreferencesByKey,
  getUserPreferencesSubject
} from '../../../store/User';
import { CustomizeDashboardDrawer } from '../../Compounds/CustomizeDashboardDrawer';
import { Tab } from '@headlessui/react';
import { filteredClosedTicketsByMonth, getTabListItemClassName } from '../../../utils/helpers';
import { UpstackDataGrid } from '../../Compounds/UpstackDataGrid/UpstackDataGrid';
import { GridSingleSelectColDef } from '@mui/x-data-grid-pro';
import { DefaultTicketGridColumnDefs } from './SupportTicketGridColDefs';
import { getGridColumns } from '../../Compounds/UpstackDataGrid/helpers';

export const SupportTicketsPage = () => {
  const page = 'Tickets';
  const [tickets, setTickets] = useState<SupportTicketsItem[]>([]);
  const [ticketsLoading, setTicketsLoading] = useState<boolean>(false);
  const [search] = useSearchParams();
  const detailId = search.get('detailId');
  const { fetchTickets } = useSupportTickets();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [ticketColumns, setTicketColumns] = useState<GridSingleSelectColDef[]>();
  const [gridSettings, setGridSettings] = useState<GridPreferences>(
    getDefaultPreferences().content['ticket_grid'] as GridPreferences
  );
  const [tabs, setTabs] = useState<{ name: string; tickets: SupportTicketsItem[] }[]>([]);

  // Subscribe to and load data.
  useEffect(() => {
    const ticketStore = getTicketSubject().subscribe((ticketData) => {
      if (ticketData?.length) {
        setTickets(ticketData);

        const tabs = [
          { name: 'Open', tickets: ticketData.filter((item) => OPEN_STATUS.includes(item.status)) },
          { name: 'Recently Closed', tickets: filteredClosedTicketsByMonth(ticketData, 2) },
          { name: 'All', tickets: ticketData }
        ];

        setTabs(tabs);
        setTicketColumns(getGridColumns(tabs?.[selectedIndex]?.tickets, DefaultTicketGridColumnDefs, 'ticket_grid'));
      }
    });
    const loadingSubscription = getTicketLoadingSubject().subscribe((loading) => setTicketsLoading(loading));
    const settingsSub = getUserPreferencesSubject().subscribe(() => {
      const gridPrefs = getPreferencesByKey('ticket_grid') as GridPreferences;
      if (gridPrefs) {
        setGridSettings(gridPrefs);
      }
    });

    fetchTickets();

    return () => {
      if (ticketStore) ticketStore?.unsubscribe();
      if (loadingSubscription) loadingSubscription.unsubscribe();
      if (settingsSub) settingsSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (tabs.length) handleTabChange(0);
  }, [tabs.length]);

  const handleTabChange = (index: number) => {
    setSelectedIndex(index);

    const columns = getGridColumns(tabs?.[index]?.tickets, DefaultTicketGridColumnDefs, 'ticket_grid');

    setTicketColumns(columns);
  };

  const tableCardActions = () => (
    <PermissibleRender requiredPermissions={[Permission.CREATE_TICKETS_FROM_TICKETS]}>
      <SupportTicketWizard
        ctaClassName="text-[indigo] text-sm"
        onFormSubmissionHandler={async () => setTickets(getTickets())}
      />
    </PermissibleRender>
  );

  const cardActions = () => <CustomizeDashboardDrawer title="Support" />;

  return (
    <PageLayout pageTitle="Support">
      <>
        <PageCard
          title="Dashboard"
          cardActions={cardActions()}>
          <DashboardWidgets
            inventoryAssets={[]}
            pageTitle={page}
            tickets={tickets}
          />
        </PageCard>

        <PageCard
          childrenWrapperClass="min-h-80 max-w-[60vw] min-w-full"
          title={page}
          cardActions={tableCardActions()}>
          <Tab.Group
            selectedIndex={selectedIndex}
            as="div"
            onChange={(index: number) => handleTabChange(index)}>
            <Tab.List className="border-b border-t bg-grey-1 border-gray-200 my-2">
              {tabs?.map((tab, index) => (
                <Tab
                  key={index}
                  name={tab.name}
                  className={getTabListItemClassName(index, selectedIndex)}>
                  <span>
                    {tab.name} - {tab.tickets.length}
                  </span>
                </Tab>
              ))}
            </Tab.List>

            <Tab.Panels className="bg-white">
              {tabs?.map((tab, index) => (
                <Tab.Panel key={index}>
                  {!!ticketColumns?.length && !ticketsLoading && (
                    <UpstackDataGrid
                      title={`Support Tickets - ${tab.name}`}
                      rows={tab.tickets}
                      columns={ticketColumns}
                      loadingData={ticketsLoading}
                      pinnedColumns={{ left: ['details', 'account_id'] }}
                      page="ticket_grid"
                      gridSettings={gridSettings}
                      showSearch={false}
                    />
                  )}
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </PageCard>
        <div className="h-2"></div>
        <DetailsDrawer
          data={tickets?.find((t) => t.id === detailId) || ({} as SupportTicketsItem)}
          showDrawer={!!detailId}
          link={`/support-ticketing/${detailId}`}
          {...getMetaData('support')}
        />
      </>
    </PageLayout>
  );
};
