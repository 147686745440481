// This should match what we have in tailwind.config.js for textSizes
const TextSizes = {
  xs: 'text-xs',
  sm: 'text-sm',
  sm14: 'text-[14px]',
  base: 'text-base',
  lg: 'text-lg',
  md: 'text-md',
  xl: 'text-xl',
  xxl: 'text-xxl',
  xxxl: 'text-xxxl'
} as const;

export const TextColors = {
  blue2: 'text-blue2',
  indigo: 'text-indigo',
  indigoMid: 'text-indigo-mid',
  indigoLight: 'text-indigo-light',
  plum: 'text-plum',
  red: 'text-red',
  black: 'text-black',
  orange: 'text-orange',
  yellow: 'text-yellow',
  slate: 'text-slate-500',
  grey0: 'text-grey-0',
  grey1: 'text-grey-1',
  grey2: 'text-grey-2',
  grey3: 'text-grey-3',
  grey4: 'text-grey-4',
  grey5: 'text-grey-5',
  grey6: 'text-grey-6',
  grey7: 'text-grey-7',
  grey8: 'text-grey-8',
  grey9: 'text-grey-9',
  grey10: 'text-grey-10',
  green: 'text-green'
};

const TextWeights = {
  thin: 'font-thin',
  extralight: 'font-extralight',
  light: 'font-light',
  normal: 'font-normal',
  medium: 'font-medium',
  semibold: 'font-semibold',
  bold: 'font-bold',
  extrabol: 'font-extrabol',
  black: 'font-black'
};

type TextSize = keyof typeof TextSizes;
export type TextColor = keyof typeof TextColors;
type TextWeight = keyof typeof TextWeights;

export interface TextProps {
  children: React.ReactNode;
  className?: string;
  size?: TextSize;
  color?: TextColor;
  weight?: TextWeight;
  isSpan?: boolean;
  srOnly?: boolean;
  dataTestId?: string;
  dataCy?: string;
}

const Text = ({
  className = '',
  srOnly = false,
  color = 'black',
  weight = 'normal',
  isSpan = false,
  size = 'base',
  dataTestId = '',
  dataCy = '',
  children
}: TextProps) => {
  const Component = isSpan ? 'span' : 'p';

  return (
    <Component
      className={`font-sans ${TextColors[color]} ${TextSizes[size]} ${TextWeights[weight]} ${className} ${
        srOnly ? 'sr-only' : ''
      }`}
      data-testid={dataTestId}
      data-cy={dataCy}>
      {children}
    </Component>
  );
};

const SrOnlyText = ({ ...props }: Omit<TextProps, 'srOnly'>) => (
  <Text
    {...props}
    srOnly={true}
  />
);

const TextSpan = ({ ...props }: Omit<TextProps, 'isSpan'>) => (
  <Text
    {...props}
    isSpan={true}
  />
);
const TextBlock = ({ ...props }: Omit<TextProps, 'isSpan'>) => (
  <Text
    {...props}
    isSpan={false}
  />
);

export { Text as default, Text, SrOnlyText, TextSpan, TextBlock };
