import { UserPreferences } from '../../store/User';

export const updateSettingsApi = async (settings: UserPreferences, type?: string) => {
  const token = localStorage.getItem('token');

  if (token) {
    const params = type ? `?type=${type}` : '';
    const response = await fetch(
      `${process.env.REACT_APP_API_SERVER_URL}/api/preferences/user_customizations/${settings.id}${params}`,
      {
        method: 'PUT',
        headers: new Headers({
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`
        }),
        body: JSON.stringify(settings),
        keepalive: true
      }
    );

    const data = await response.json();
    return response.ok ? { data, error: null } : { data: null, error: data };
  }

  return { data: null, error: { message: 'Not authenticated or missing token' } };
};
