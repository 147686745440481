import { Route, Routes } from 'react-router-dom';
import { LoginPageDevise } from './Pages';
import { MaintenancePage } from './Pages/Maintenance';
import { ResetPasswordPage } from './Pages/ResetPassword/ResetPasswordPage';

export default function PublicAppRoutes() {
  return (
    <Routes>
      <Route
        path="/login"
        element={<LoginPageDevise />}
      />
      <Route
        path={'/portal/offline'}
        element={<MaintenancePage />}
      />
      <Route
        path={'/reset'}
        element={<ResetPasswordPage />}
      />
      <Route
        path={'/welcome'}
        element={<LoginPageDevise />}
      />
    </Routes>
  );
}
