import {
  Autocomplete,
  Box,
  Checkbox,
  ClickAwayListener,
  Divider,
  FormControlLabel,
  Paper,
  TextField
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { Account } from '../../../types';
import { HTMLAttributes, useEffect, useState } from 'react';
import { getAccounts, getSelectedAccountsSubject } from '../../../store/User';
import Text, { TextSpan } from '../../Atoms/Text';
import { truncateStr } from '../../../utils/helpers';

export interface AccountMultiSelectProps {
  accounts: Account[];
  handleAccountSelect: (acct: Account[] | null) => void;
}

export const AccountMultiSelect = ({ accounts, handleAccountSelect }: AccountMultiSelectProps) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [open, setOpen] = useState(false);
  const [displayAccounts, setDisplayAccounts] = useState<Account[]>([]);
  const [selectedAccounts, setSelectedAccounts] = useState<Account[] | null>([]);

  const handleChange = (e: React.SyntheticEvent<Element, Event>, value: Account[] | null) => {
    handleAccountSelect(value);
  };

  const isSelected = (id: string) => {
    const selectedIds = selectedAccounts?.map((acc) => acc.id);
    return selectedIds?.includes(id);
  };

  const handleSelectAll = () => {
    handleAccountSelect(getAccounts() || []);
  };

  const handleSelectNone = () => {
    handleAccountSelect([]);
  };

  useEffect(() => {
    const selectedAcctSub = getSelectedAccountsSubject().subscribe((acct) => {
      setSelectedAccounts(acct);
      const selectedIds = acct?.map((acc) => acc.id);

      const displayAcct = accounts.reduce((acc: Account[], element) => {
        if (selectedIds?.includes(element?.id)) {
          return [element, ...acc];
        }

        return [...acc, element];
      }, []);

      setDisplayAccounts(displayAcct);
    });

    return () => {
      if (selectedAcctSub) selectedAcctSub.unsubscribe();
    };
  }, []);

  const paperComponent = (paperProps: HTMLAttributes<HTMLElement>) => {
    const { children, ...restPaperProps } = paperProps;
    return (
      <Paper {...restPaperProps}>
        <Box
          onMouseDown={(e) => e.preventDefault()} // prevent blur
          pl={1.5}
          py={0.5}>
          <div className="flex space-x-4">
            <TextSpan size="sm14">Select: </TextSpan>
            <button
              onClick={(e) => {
                e.preventDefault(); // prevent blur
                handleSelectAll();
              }}
              className="text-indigo">
              All
            </button>
            <button
              onClick={(e) => {
                e.preventDefault(); // prevent blur
                handleSelectNone();
              }}
              className="text-indigo">
              None
            </button>
          </div>
        </Box>
        <Divider />
        {children}
      </Paper>
    );
  };

  const getDisplay = () => {
    // Single account return here
    if (selectedAccounts?.length === 1) return selectedAccounts?.[0]?.name;

    // Multiple accounts selected.
    const more = selectedAccounts?.slice(2);
    return selectedAccounts?.length ? (
      <div className="flex items-center">
        <Text className="text-[.90rem] truncate">
          {truncateStr(`${selectedAccounts?.[0]?.name}, ${selectedAccounts?.[1]?.name}`, 28)}
        </Text>
        {selectedAccounts?.length > 2 && (
          <>
            <Text>, and</Text>
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <Tooltip
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: 'common.white',
                      '& .MuiTooltip-arrow': {
                        color: 'lightgrey'
                      },
                      color: 'common.black',
                      border: '1px solid lightgrey'
                    }
                  }
                }}
                PopperProps={{
                  disablePortal: true
                }}
                onClose={() => setOpen(false)}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={
                  <pre className="font-sans text-[0.90rem] font-normal">{more?.map((a) => a.name).join('\n')}</pre>
                }>
                <button
                  className="ml-1 text-indigo"
                  onClick={() => setOpen(true)}>{`${more?.length} more`}</button>
              </Tooltip>
            </ClickAwayListener>
          </>
        )}
      </div>
    ) : null;
  };

  return (
    <div className="flex items-center w-[27rem]">
      <Autocomplete
        id="user-account-selection-input"
        data-testid="user-account-selection-input"
        value={selectedAccounts || undefined}
        disableCloseOnSelect
        multiple
        disabled={displayAccounts.length === 1}
        className="w-full"
        isOptionEqualToValue={(option, value) => option.name === value.name}
        renderTags={() => (selectedAccounts?.length ? getDisplay() : null)}
        inputValue={searchQuery}
        onInputChange={(e, val: string) => setSearchQuery(val)}
        ListboxProps={{ style: { maxHeight: 150 } }}
        onChange={handleChange}
        onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
        options={displayAccounts}
        getOptionLabel={(option: Account) => option?.name || ''}
        size="small"
        sx={{
          '.MuiInputBase-input': {
            height: '0.75rem'
          },
          'MuiFormControl-root': {
            height: 'unset'
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{ ...params.InputProps, style: { fontSize: '.90rem' } }}
            className="h-[1.75rem] text-nowrap"
            data-cy="account-input"
          />
        )}
        renderOption={(props, option) => {
          return (
            <li
              {...props}
              className={`text-sm14 py-1 pl-4 cursor-pointer hover:bg-grey-1 ${isSelected(option.id) ? 'bg-grey-2' : ''}`}
              key={`${option.id}-${option.name}`}>
              <FormControlLabel
                control={<Checkbox checked={isSelected(option.id)} />}
                label={option.name}
              />
            </li>
          );
        }}
        PaperComponent={(paperProps) => paperComponent(paperProps)}
      />
    </div>
  );
};
