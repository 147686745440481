import Popup from 'reactjs-popup';
import { DEFAULT_ADVANCE_BUTTON_STYLE, DEFAULT_CANCEL_BUTTON_STYLE } from '../../../utils/styleHelpers';
import { useForm } from 'react-hook-form';
import { GridPreferences, getCurrentPreferences, gridInitialState, updateUserSettings } from '../../../store/User';
import { useState, useRef } from 'react';
import { Icon } from '../../Atoms/Icon';
import { Toggle } from '../../Atoms/Toggle';
import { Text } from '../../Atoms/Text';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { getState } from '../../../store/GeneralStore';
import { v4 } from 'uuid';

export const INPUT_STYLE =
  'w-full h-[1.8rem] border border-gray-300 bg-white rounded-md focus:ring-blue-500 focus:border-blue-500 p-2';

export type AddNewViewProps = {
  setCurrentCustomView: (name: string) => void;
  currentPage: string;
};

export interface PopupActions {
  open: () => void;
  close: () => void;
  toggle: () => void;
}
interface FormData {
  name: string;
}

const contentStyle = {
  borderRadius: '10px',
  border: '2px solid #F7F7F7',
  width: '250px',
  padding: '10px'
};

export function AddNewView({ setCurrentCustomView, currentPage }: AddNewViewProps) {
  const ref = useRef<PopupActions | null>(null);
  const [collapse, setCollapse] = useState(false);
  const toggleTooltip = () => ref?.current?.toggle();
  const { register, handleSubmit, reset } = useForm<FormData>();
  const [useCurrentView, setUseCurrentView] = useState<boolean>(true);

  const handleSubmitForm = (formData: FormData): void => {
    const currentSettings = { ...getCurrentPreferences() };

    let newView: GridPreferences = {
      customView: true,
      muiConfig: gridInitialState() as GridInitialStatePro,
      disabledFields: [],
      id: v4()
    };
    const tableRef = getState()?.gridRefs[`inventory_grid_${currentPage}`];
    const currentTabTableState = tableRef?.current?.exportState();

    if (useCurrentView) {
      newView = {
        ...newView,
        muiConfig: currentTabTableState
      };
    }

    // Update currentPage settings to preserve any pref changes made since last page unload.
    currentSettings.content[`inventory_grid_${currentPage}`] = {
      muiConfig: currentTabTableState,
      disabledFields: currentSettings.content[`inventory_grid_${currentPage}`]?.disabledFields || []
    };

    // Add new view.
    currentSettings.content[`inventory_grid_${formData.name}`] = newView;

    updateUserSettings(currentSettings, `inventory_grid_${formData.name}`);
    setCurrentCustomView(formData.name);

    ref.current?.close();
    reset({});
  };

  const handleToggleChange = (oldUseCurrentView: boolean) => {
    setUseCurrentView(!oldUseCurrentView);
  };

  const onCancel = () => {
    ref?.current?.toggle();
    reset({});
  };

  const onChangeCollapse = () => {
    setTimeout(() => setCollapse(!collapse), 100);
  };

  return (
    <div className="inline-flex px-4">
      <Popup
        className="custom-tooltip tooltip-add-view"
        ref={ref}
        trigger={
          <button
            type="button"
            className="inline-flex self-center mr-1 mr--1 absolute">
            <Icon type="plus" />
          </button>
        }
        position="bottom center"
        contentStyle={contentStyle}
        onOpen={onChangeCollapse}
        onClose={onChangeCollapse}>
        <form
          data-cy="add-view"
          aria-label="Add View"
          onSubmit={handleSubmit(handleSubmitForm)}>
          <div className="form-group">
            <input
              className={`${INPUT_STYLE} h-[1.8rem]`}
              data-cy="name"
              id="name"
              {...register('name', {
                required: true
              })}
              placeholder="New View"
              type="text"
            />
          </div>
          <label
            className="space-x-2 my-3 flex"
            htmlFor="toggle-use-view">
            <Toggle
              id="toggle-use-view"
              className="inline-block"
              checked={useCurrentView}
              onChange={() => handleToggleChange(useCurrentView)}
              width={7}
              height={4}
            />
            <Text
              size="sm"
              className="text-gray-700"
              isSpan>
              Use current view settings
            </Text>
          </label>
          <div className="flex justify-between flex-col">
            <button
              type="submit"
              className={`${DEFAULT_ADVANCE_BUTTON_STYLE} w-full py-[0.2rem]`}>
              Save
            </button>
            <button
              type="button"
              className={`${DEFAULT_CANCEL_BUTTON_STYLE} w-full py-[0.2rem] mt-2`}
              onClick={onCancel}>
              Cancel
            </button>
          </div>
        </form>
      </Popup>
      <button
        type="button"
        onClick={collapse ? undefined : toggleTooltip}
        className="py-4">
        <span className="font-sans text-black text-base font-normal flex items-center space-x-2 ml-5">
          <span
            className="text-[indigo] text-sm"
            data-cy="new-view">
            New View
          </span>
        </span>
      </button>
    </div>
  );
}
