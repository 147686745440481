import { useState } from 'react';
import DOMPurify from 'dompurify';
import { Text, TextBlock } from '../../Atoms/Text';
import parse from 'html-react-parser';
import { format, parseISO } from 'date-fns';
import { Comment, useCommentsStore } from '../../../store/Comment';
import { getUser, isAdmin, isSupport } from '../../../store/User';
import { TextEditor } from './TextEditor';
import { PortalUserData } from '../../../types';

export interface CommentItemProps {
  comment: Comment;
  handleReply: (comment: Comment) => void;
  mentionableUsers: PortalUserData[];
}

export function CommentItem({ comment, handleReply, mentionableUsers }: CommentItemProps) {
  const [showMore, setShowMore] = useState<boolean>(false);
  const [isArchiving, setIsArchiving] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { archiveComment, updateComment } = useCommentsStore();
  const user = getUser();
  const isOwner = String(comment.portal_user_id) == user.id;

  const isArchivable = () => {
    return !comment.is_archived && (isOwner || isAdmin(user) || isSupport(user));
  };

  const handleArchive = () => {
    setIsArchiving(true);
    setIsEditing(false);
    archiveComment(comment);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  const handleUpdateComment = (comment_text: string) => {
    updateComment({ ...comment, comment_text });
    setIsEditing(false);
  };

  return (
    <div className={`border-b border-grey-3 mb-4 pb-4 ${comment.parent_comment_id ? 'ml-6' : ''}`}>
      <div className="flex gap-2">
        {!!comment.portal_user_name && (
          <TextBlock
            size="sm"
            weight="medium">
            {comment.portal_user_name}
          </TextBlock>
        )}
        {comment.created_at && (
          <TextBlock
            size="sm"
            color="grey6">
            {format(parseISO(comment.created_at), 'PPP p')}
          </TextBlock>
        )}
        {comment.text_changed_at && (
          <TextBlock
            size="sm"
            color="grey6">
            (edited)
          </TextBlock>
        )}
      </div>
      <div className={isEditing ? 'mt-4' : ''}>
        {!isEditing && (
          <div className="text-black text-sm ql-editor pl-0">
            {parse(
              DOMPurify.sanitize(
                comment.comment_text.length > 500 && !showMore
                  ? `${comment.comment_text.substring(0, 500)}<span>...</span>`
                  : comment.comment_text
              )
            )}
          </div>
        )}
        {isEditing && (
          <TextEditor
            commentText={comment.comment_text}
            handleCancel={handleCancelEdit}
            handleSend={handleUpdateComment}
            portalUsers={mentionableUsers}
            placeholder="Comment here"
          />
        )}
      </div>
      <div className="flex gap-4 mt-4">
        {!isEditing && comment.comment_text.length > 500 && (
          <button onClick={() => setShowMore(!showMore)}>
            <Text
              size="sm"
              color="indigoMid">
              {showMore ? 'Show Less' : 'Show More'}
            </Text>
          </button>
        )}
        {isArchivable() && (
          <button
            disabled={isArchiving}
            onClick={() => handleArchive()}>
            <Text
              size="sm"
              color="indigoMid">
              Delete
            </Text>
          </button>
        )}
        <button onClick={() => handleReply(comment)}>
          <Text
            size="sm"
            color="indigoMid">
            Reply
          </Text>
        </button>
        {!comment.is_archived && isOwner && (
          <button
            disabled={isArchiving}
            onClick={() => handleEdit()}>
            <Text
              color="indigoMid"
              size="sm">
              Edit
            </Text>
          </button>
        )}
      </div>
    </div>
  );
}
