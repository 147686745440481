import Popup from 'reactjs-popup';
import { useRef } from 'react';
import { Icon } from '../../Atoms/Icon';
import { MenuRow } from './MenuRow';
import { SearchGlass } from '../../partials/SearchGlass';
import { useState } from 'react';
import { INPUT_STYLE } from '../../../utils/styleHelpers';

export type MenuProps = {
  setCurrentCustomView: (name: string) => void;
  customTabs: string[];
  handleTabChange: (index: number, name: string) => void;
  indexTab: number;
  currentPage: string;
  selectedIndex?: number;
};

interface PopupActions {
  open: () => void;
  close: () => void;
  toggle: () => void;
}

export function Menu({
  setCurrentCustomView,
  customTabs,
  handleTabChange,
  indexTab,
  currentPage,
  selectedIndex
}: MenuProps) {
  const ref = useRef<PopupActions | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [collapse, setCollapse] = useState(false);
  const toggleTooltip = (event: React.MouseEvent<HTMLButtonElement>) => {
    ref?.current?.toggle();
    event.preventDefault();
  };

  const withSearch = customTabs.length > 5;
  const contentStyle = {
    border: '2px solid #F7F7F7',
    width: '300px',
    padding: '10px 20px',
    maxHeight: withSearch ? '300px' : '245px',
    overflow: 'auto',
    margin: '43px -2px',
    borderTop: 'none'
  };
  const collapseClass = collapse ? '-rotate-90' : 'rotate-90';
  const filteredTabs =
    withSearch && searchValue
      ? customTabs.filter((tab) => tab.toLowerCase().includes(searchValue.toLowerCase()))
      : customTabs;

  const handleCustomTabChange = (name: string) => {
    setCurrentCustomView(name);
    handleTabChange(indexTab, name);
  };

  const changeView = (name: string, newName: string, reset?: boolean) => {
    if (reset) {
      handleTabChange(0, 'All');
      ref.current?.close();
    }
    if (name === currentPage && newName) {
      setCurrentCustomView(newName);
    } else {
      const index = customTabs.indexOf(name);
      const newPage = customTabs.length > 1 && (customTabs[index - 1] || customTabs[0]);
      if (newPage) {
        setCurrentCustomView(newPage);
      } else if (indexTab == selectedIndex) {
        handleTabChange(0, 'All');
      }
    }
  };

  const handleSearch = (e: React.FormEvent<HTMLInputElement>) => {
    const search = e?.currentTarget?.value;
    setTimeout(() => setSearchValue(search), 500);
  };

  const onChangeCollapse = () => {
    setTimeout(() => setCollapse(!collapse), 100);
  };

  return (
    <>
      <Popup
        className="custom-tooltip tooltip-custom-views"
        ref={ref}
        trigger={<div className="inline-block absolute left-0" />}
        position="bottom left"
        closeOnDocumentClick
        contentStyle={contentStyle}
        arrow={true}
        onOpen={onChangeCollapse}
        onClose={onChangeCollapse}>
        <>
          {withSearch && (
            <form className="sticky top-0">
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <SearchGlass />
                </div>
                <input
                  onChange={handleSearch}
                  type="search"
                  id="search-tabs"
                  name="search"
                  className={INPUT_STYLE}
                  style={{ paddingLeft: '2.5rem', paddingRight: '1rem' }}
                  placeholder="Search"
                  defaultValue={searchValue}
                />
              </div>
            </form>
          )}
          {filteredTabs.map((name, index) => (
            <MenuRow
              {...{ index, name, handleCustomTabChange, changeView }}
              key={`${index}-${name}`}
            />
          ))}
        </>
      </Popup>
      <div className="my-auto pb-1 mr-4">
        <button
          className={`${collapseClass} -ml-5`}
          type="button"
          onClick={collapse ? undefined : toggleTooltip}>
          <Icon
            className="p-1"
            type="dropdown"
          />
        </button>
      </div>
    </>
  );
}
