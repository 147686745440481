import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Tab } from '@headlessui/react';
import { InventoryAsset } from '../../../types';
import { PageLayout } from '../../PageLayout';
import { PageCard } from '../../Compounds/CardWithTitle';
import { DashboardWidgets } from '../../Compounds/DashboardWidgets/DashboardWidgets';
import { DetailsDrawer } from '../../Compounds/DetailsDrawer';
import { getMetaData } from '../../Compounds/DetailsDrawer/detailsDrawersHelpers';
import { getInventoryLoadingSubject, getInventorySubject, useInventory } from '../../../store/Inventory';
import { GridPreferences, PrefKey, UserPreferencesContent, getUserPreferencesSubject } from '../../../store/User';
import { FeatureFlags, defaultFeatureFlags, getFeatureFlagSubject } from '../../../Api/useFeatureFlags';
import { CustomizeDashboardDrawer } from '../../Compounds/CustomizeDashboardDrawer';
import { getColumnsByProduct, getTabData } from '../../Compounds/UpstackDataGrid/inventoryUtils';
import { getTabListItemClassName } from '../../../utils/helpers';
import { UpstackDataGrid } from '../../Compounds/UpstackDataGrid/UpstackDataGrid';
import { getGridColumns } from '../../Compounds/UpstackDataGrid/helpers';
import { GridSingleSelectColDef } from '@mui/x-data-grid-pro';
import { PermissibleRender } from '../../Compounds/PermissibleRender';
import { Permission } from '../../../store/GeneralStore';
import { Icon } from '../../Atoms/Icon';
import { TextSpan } from '../../Atoms/Text';
import { useProductsStore } from '../../../store/Product';
import { useOutsideInventorySchemasStore } from '../../../store/OutsideInventorySchema';
import { Menu as CustomViewTabs } from './../../Compounds/CustomTableViews/Menu';
import { AddNewView } from './../../Compounds/CustomTableViews/AddNewView';
import { combineLatest } from 'rxjs';

export interface Tab {
  name: string;
  count: number;
  customView?: boolean;
}
export interface CategorizedData {
  [key: string]: InventoryAsset[];
}

const getSortedCustomTabs = (obj: UserPreferencesContent, prefix: string): string[] => {
  return Object.keys(obj)
    .filter((key) => key.startsWith(prefix) && ((obj?.[key as PrefKey] || {}) as GridPreferences).customView)
    .map((key) => key.substring(prefix.length))
    .sort((a, b) => (a < b ? -1 : 1));
};

export const InventoryPage = () => {
  const page = 'Inventory';
  const [inventoryAssets, setInventoryAssets] = useState<InventoryAsset[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [tabs, setTabs] = useState<Tab[]>([]);
  const [allTabs, setAllTabs] = useState<Tab[]>([]);
  const [customTabs, setCustomTabs] = useState<string[]>([]);
  const [categorizedData, setCategorizedData] = useState<CategorizedData>({});
  const [inventoryLoading, setInventoryLoading] = useState<boolean>(false);
  const [columns, setColumns] = useState<GridSingleSelectColDef[]>();
  const [currentCustomView, setCurrentCustomView] = useState<string>('');
  const [search] = useSearchParams();
  const detailId = search.get('detailId');
  const { fetchInventory } = useInventory();
  const { fetchProducts } = useProductsStore();
  const { fetchOutsideInventorySchemas } = useOutsideInventorySchemasStore();
  const [userPreferences, setUserPreferences] = useState<UserPreferencesContent>();
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>(defaultFeatureFlags);
  const state = { prevpath: location.pathname };

  useEffect(() => {
    const dataSubscription = combineLatest({
      inventory: getInventorySubject(),
      settings: getUserPreferencesSubject(),
      inventoryLoading: getInventoryLoadingSubject(),
      flags: getFeatureFlagSubject()
    }).subscribe(({ inventory, settings, inventoryLoading, flags }) => {
      // Inventory Assets
      setInventoryAssets(inventory);
      const { tabs, categorizedData } = getTabData(inventory);
      setTabs(tabs);
      setCategorizedData(categorizedData);
      setColumns(
        getGridColumns(
          (categorizedData as CategorizedData)[tabs[selectedIndex]?.name || 'All'],
          getColumnsByProduct(tabs[selectedIndex]?.name || 'All'),
          `inventory_grid_${tabs[selectedIndex]?.name || 'All'}`
        )
      );
      // Inventory Loading
      setInventoryLoading(inventoryLoading);

      // Flags
      setFeatureFlags(flags);

      // User Settings
      const content = settings.content;
      setUserPreferences(content);
    });

    fetchInventory();
    fetchProducts();
    fetchOutsideInventorySchemas();

    return () => {
      if (dataSubscription) dataSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (userPreferences) {
      let customTab: Tab[] = [];
      if (featureFlags.my_views) {
        const customTabs = getSortedCustomTabs(userPreferences, 'inventory_grid_');
        if (!currentCustomView && customTabs.length) setCurrentCustomView(customTabs[0]);
        customTab = customTabs.length ? [{ name: currentCustomView, count: 0, customView: true }] : [];
        setCustomTabs(customTabs);
      }

      setAllTabs(tabs.length ? tabs.concat(customTab) : []);
    }
  }, [userPreferences, tabs]);

  useEffect(() => {
    const customTab = customTabs.length
      ? [{ name: currentCustomView || customTabs[0], count: 0, customView: true }]
      : [];
    setAllTabs(tabs.length ? tabs.concat(customTab) : []);
  }, [currentCustomView]);

  useEffect(() => {
    if (tabs?.length) handleTabChange(0, tabs?.[0]?.name);
  }, [tabs?.length]);

  const tableCardActions = () => {
    if (featureFlags.outside_inventory) {
      return (
        <PermissibleRender requiredPermissions={[Permission.CREATE_OUTSIDE_INVENTORY]}>
          <Link
            role="link"
            title="Add Inventory"
            className="flex space-x-2 items-center"
            data-cy="new-outside-inventory"
            state={state}
            to="/add-inventory">
            <Icon
              type="plus"
              className="p-2"
            />
            <TextSpan
              color="indigo"
              size="sm14">
              Add Inventory
            </TextSpan>
          </Link>
        </PermissibleRender>
      );
    }
  };

  const cardActions = () => <CustomizeDashboardDrawer title="Inventory" />;

  const handleTabChange = (index: number, name: string) => {
    setSelectedIndex(index);
    const columns = getGridColumns(
      categorizedData[name] || categorizedData['All'],
      getColumnsByProduct(name),
      `inventory_grid_${name}`
    );
    setColumns(columns);
  };

  const labelTab = (tab: Tab, indexTab: number) => {
    if (tab.customView) {
      return <div className="truncate max-w-44 p-2">{tab.name}</div>;
    }

    return (
      <>
        {tab.name} - {categorizedData[tab.name]?.length}
      </>
    );
  };

  const renderTab = (tab: Tab, index: number) => {
    return (
      <span className="relative inline-flex">
        <Tab
          key={index}
          name={tab.name}
          className={getTabListItemClassName(index, selectedIndex)}>
          {labelTab(tab, index)}
        </Tab>
        {tab.customView && featureFlags.my_views && (
          <CustomViewTabs
            {...{
              setCurrentCustomView,
              customTabs,
              handleTabChange,
              indexTab: index,
              currentPage: tab.name,
              selectedIndex
            }}
          />
        )}
      </span>
    );
  };

  return (
    <PageLayout pageTitle={page}>
      <>
        <PageCard
          title="Dashboard"
          cardActions={cardActions()}>
          <DashboardWidgets
            inventoryAssets={inventoryAssets}
            pageTitle={page}
            tickets={[]}
          />
        </PageCard>
        <PageCard
          childrenWrapperClass="min-h-80 max-w-[60vw] min-w-full"
          title={page}
          cardActions={tableCardActions()}>
          <Tab.Group
            selectedIndex={selectedIndex}
            as="div"
            onChange={(index: number) => handleTabChange(index, allTabs?.[index]?.name)}>
            <Tab.List className="border-b border-t bg-grey-1 border-gray-200 my-2">
              {allTabs.map(renderTab)}
              {allTabs.length > 1 && featureFlags.my_views && (
                <AddNewView {...{ setCurrentCustomView, currentPage: allTabs[selectedIndex]?.name }} />
              )}
            </Tab.List>
            <Tab.Panels className="bg-white">
              {allTabs.map((tab, index) => (
                <Tab.Panel key={`${index}`}>
                  <UpstackDataGrid
                    title={`Inventory - ${tab.name}`}
                    rows={categorizedData[tab.name] || categorizedData['All']}
                    columns={columns}
                    pinnedColumns={{ left: ['details', 'UPSTACK Managed', 'account_id'] }}
                    loadingData={inventoryLoading}
                    page={`inventory_grid_${tab.name}`}
                    gridSettings={(userPreferences?.[`inventory_grid_${tab.name}` as PrefKey] || {}) as GridPreferences}
                    showSearch
                  />
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </PageCard>
        <div className="h-2"></div>
        <DetailsDrawer
          data={inventoryAssets?.find((ia: InventoryAsset) => ia?.id === detailId) || ({} as InventoryAsset)}
          showDrawer={!!detailId}
          link={`/inventory/${detailId}`}
          {...getMetaData('inventory')}
        />
      </>
    </PageLayout>
  );
};
