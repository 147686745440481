export const updateUsersApi = async (userId: string) => {
  const token = localStorage.getItem('token');

  if (token) {
    const response = await fetch(`${process.env.REACT_APP_API_SERVER_URL}/api/user/${userId}`, {
      method: 'PUT',
      headers: new Headers({
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`
      }),
      body: JSON.stringify({ user: { tos_read: true } }),
      keepalive: true
    });
    const data = await response.json();

    return { data, error: null };
  }

  return { data: null, error: { message: 'Not authenticated or missing token' } };
};
