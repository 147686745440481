import React, { useEffect, useState } from 'react';
import { Account } from '../../../types';
import { Text } from '../../Atoms/Text';
import { Checkbox, FormControlLabel } from '@mui/material';
import {
  bulkUpdateSharedAccountCustomizations,
  defaultSharedAccountCustomizationData,
  fetchSharedAccountCustomizations,
  getSharedAccountCustomizationsSubject,
  SharedAccountCustomizationData
} from '../../../store/SharedAccountCustomizations';
import { AccountSelectionForm } from '../../Compounds/UserCreation';
import { useSnackbar } from '../../../Context/SnackbarContext';
import { DEFAULT_ADVANCE_BUTTON_STYLE, DEFAULT_CANCEL_BUTTON_STYLE } from '../../../utils/styleHelpers';
import { emailRegex } from '../../../utils/helpers';
import CompanySettingsBulkSaveDialog from './CompanySettingsBulkSaveDialog';
import AdditionalRecipients from './AdditionalRecipients';
import { fetchMyTeam } from '../../../Api/MyTeam/fetchMyTeam';
import { getUser } from '../../../store/User';
import { useNavigate } from 'react-router-dom';

const CompanySettingsTab: React.FC = () => {
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [selectedSingleAccount, setSelectedSingleAccount] = useState<Account | null>(null);
  const [selectedBulkAccounts, setSelectedBulkAccounts] = useState<Account[]>([]);
  const [additionalRecipInput, setAdditionalRecipInput] = useState<string>();
  const [sharedCustomizations, setSharedCustomizations] = useState<SharedAccountCustomizationData[]>([]);
  const [selectedSharedCustomization, setSelectedSharedCustomization] = useState<SharedAccountCustomizationData>();
  const [bulkModal, setBulkModal] = useState<boolean>(false);
  const [advisor, setAdvisor] = useState<string | null>(null);
  const [csm, setCsm] = useState<string | null>(null);
  const { setSnack } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    setAccounts(getUser().accounts);
    const sharedCustomizationsSub = getSharedAccountCustomizationsSubject().subscribe((data) => {
      setSharedCustomizations(data);
    });

    fetchSharedAccountCustomizations();

    return () => {
      if (sharedCustomizationsSub) sharedCustomizationsSub.unsubscribe();
    };
  }, []);

  const handleBulkAccountChange = (e: React.SyntheticEvent<Element, Event>, value: string[] | null) => {
    const filteredAccounts = accounts.filter((a) => value?.includes(a.name));
    setSelectedBulkAccounts(filteredAccounts || []);
    if (value && selectedBulkAccounts.length === 0) {
      const account = filteredAccounts[0];
      const sharedC = sharedCustomizations.find((i) => i.account_id === account.id);
      setSelectedSharedCustomization(sharedC || defaultSharedAccountCustomizationData());
    }
  };

  const handleAdd = () => {
    if (additionalRecipInput && emailRegex.test(additionalRecipInput)) {
      if (!selectedSharedCustomization?.content.additionalRecipients?.includes(additionalRecipInput)) {
        selectedSharedCustomization?.content.additionalRecipients?.push(additionalRecipInput);
      }
      setAdditionalRecipInput('');
    }
  };

  const onAdditionalRecipientsDelete = (email: string) => () => {
    setSelectedSharedCustomization((currentCust) => {
      if (!currentCust?.content?.additionalRecipients) return currentCust;
      const emails = currentCust.content.additionalRecipients.filter((v) => v !== email);
      return { ...currentCust, content: { ...currentCust.content, additionalRecipients: emails } };
    });
  };

  const handleSingleAccountChange = (acct: Account | undefined) => {
    setAdvisor(null);
    setCsm(null);
    if (acct) {
      getTeam(acct);
      setSelectedSingleAccount(acct);
      setSelectedBulkAccounts([acct]);
      const sharedC = sharedCustomizations.find((i) => i.account_id === acct.id);
      setSelectedSharedCustomization(sharedC || defaultSharedAccountCustomizationData());
    }
  };

  const getTeam = async (acct: Account) => {
    const { data } = await fetchMyTeam(acct.id);

    if (data) {
      setAdvisor(data.owner.email);
      setCsm(data.success_manager.email);
    }
  };

  const saveAccountCust = async () => {
    if (selectedSharedCustomization?.content?.additionalRecipients) {
      const res = await bulkUpdateSharedAccountCustomizations(selectedBulkAccounts, selectedSharedCustomization);

      if (res) {
        setSnack({
          message: 'Settings Saved',
          open: true,
          type: 'success'
        });
        closeModal();
        fetchSharedAccountCustomizations();
      } else {
        setSnack({
          type: 'error',
          message: 'Failed to save settings.',
          open: true
        });
      }
    }
  };

  const closeModal = () => {
    setBulkModal(false);
    setSelectedBulkAccounts(selectedSingleAccount ? [selectedSingleAccount] : []);
  };

  const toggleTeamMember = (email: string) => {
    if (!email) return;
    if (selectedSharedCustomization?.content.additionalRecipients?.includes(email)) {
      onAdditionalRecipientsDelete(email)();
    } else {
      setSelectedSharedCustomization((currentCust) => {
        if (!currentCust?.content?.additionalRecipients) return currentCust;
        currentCust?.content?.additionalRecipients.push(email);
        return {
          ...currentCust,
          content: { ...currentCust.content, additionalRecipients: currentCust?.content?.additionalRecipients }
        };
      });
    }
  };

  const hideBulkSave = () => {
    const user = getUser();
    const roles = [
      'Admin',
      'Support',
      'Advisor',
      'Customer Success Manager',
      'Service Delivery Manager',
      'Executive',
      'Commissions'
    ];
    return user.roles.some((i) => roles.includes(i.name));
  };

  return (
    <div>
      <div className="flex mt-5 px-1">
        <AccountSelectionForm
          accounts={accounts}
          selectedAccount={selectedSingleAccount}
          setSelectedAccount={handleSingleAccountChange}
          size="small"
          styles="w-full"
        />
      </div>

      <div className="flex mt-5 px-1">
        <Text
          className="font-semibold w-full"
          color="grey9"
          size="sm">
          Support Tickets
        </Text>
      </div>

      <hr className="my-2 h-0.5 border-t-1" />

      <div className="flex mt-5 px-1">
        <Text
          className="w-full"
          color="grey9"
          size="sm">
          Default Additional Recipients
        </Text>
      </div>

      <AdditionalRecipients
        additionalRecipients={selectedSharedCustomization?.content.additionalRecipients}
        handleAdd={handleAdd}
        additionalRecipInput={additionalRecipInput}
        setAdditionalRecipInput={setAdditionalRecipInput}
        saveDefault={false}
        onAdditionalRecipientsDelete={onAdditionalRecipientsDelete}
        showDefaultBox={false}
        styles={{
          divClassName: 'flex mt-5 px-1',
          chip: {
            marginBottom: '.5rem',
            borderRadius: '5px',
            marginRight: '.5rem'
          }
        }}
      />

      {advisor && (
        <div className="flex mt-5 px-1">
          <FormControlLabel
            className="w-full"
            control={
              <Checkbox
                checked={selectedSharedCustomization?.content.additionalRecipients?.includes(advisor)}
                onClick={() => toggleTeamMember(advisor)}
              />
            }
            label={`Include Account Advisor: ${advisor}`}
          />
        </div>
      )}

      {csm && (
        <div className="flex mt-5 px-1">
          <FormControlLabel
            className="w-full"
            control={
              <Checkbox
                checked={selectedSharedCustomization?.content.additionalRecipients?.includes(csm)}
                onClick={() => toggleTeamMember(csm)}
              />
            }
            label={`Include Customer Success Manager: ${csm}`}
          />
        </div>
      )}

      <hr className="mt-3 h-0.5 border-t-2" />

      <div className="justify-between mt-5">
        <button
          onClick={() => navigate('/')}
          className={DEFAULT_CANCEL_BUTTON_STYLE}>
          Back
        </button>

        <button
          onClick={saveAccountCust}
          className={`${DEFAULT_ADVANCE_BUTTON_STYLE} float-right`}>
          Save
        </button>

        {!hideBulkSave() && (
          <button
            onClick={() => setBulkModal(true)}
            className="float-right mr-8 mt-2 text-[indigo] text-sm">
            Bulk Save
          </button>
        )}
      </div>

      <CompanySettingsBulkSaveDialog
        open={bulkModal}
        onClose={closeModal}
        accounts={accounts}
        selectedBulkAccounts={selectedBulkAccounts}
        handleAccountSelect={handleBulkAccountChange}
        saveAccountCust={saveAccountCust}
      />
    </div>
  );
};

export default CompanySettingsTab;
