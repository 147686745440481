import React, { useEffect, useState } from 'react';
import { MenuItem, Select, SelectChangeEvent, Switch } from '@mui/material';
import { Text } from '../../Atoms/Text';
import {
  getDefaultPreferences,
  getUserPreferencesSubject,
  PrefKey,
  updateUserSettings,
  UserPreferences
} from '../../../store/User';
import { DEFAULT_ADVANCE_BUTTON_STYLE, DEFAULT_CANCEL_BUTTON_STYLE } from '../../../utils/styleHelpers';
import { useInventory } from '../../../store/Inventory';
import { useSnackbar } from '../../../Context/SnackbarContext';
import { useNavigate } from 'react-router-dom';

interface HistoricOption {
  name: string;
  value: string;
}

interface EmailNotification {
  title: string;
  switchLabel: string;
  default: boolean;
}

export interface FormMeta {
  email: {
    install_updates_email: EmailNotification;
    support_tickets_email: EmailNotification;
    comment_created_email: EmailNotification;
    comment_mention_email: EmailNotification;
  };
  app: {
    support_tickets: EmailNotification;
    comment_created: EmailNotification;
    comment_mention: EmailNotification;
  };
  historic: {
    disconnects: {
      title: string;
      options: HistoricOption[];
    };
    cancellations: {
      title: string;
      options: HistoricOption[];
    };
  };
}

const defaultFormMeta: FormMeta = {
  email: {
    install_updates_email: {
      title: 'Service Installations',
      switchLabel: 'Email Notifications',
      default: false
    } as EmailNotification,
    support_tickets_email: {
      title: 'Support Tickets',
      switchLabel: 'Email Notifications',
      default: false
    } as EmailNotification,
    comment_created_email: {
      title: 'Comment Created',
      switchLabel: 'Email Notifications',
      default: false
    } as EmailNotification,
    comment_mention_email: {
      title: 'Comment Mention',
      switchLabel: 'Email Notifications',
      default: false
    } as EmailNotification
  },
  app: {
    support_tickets: {
      title: 'Support Tickets',
      switchLabel: 'App Notifications',
      default: false
    } as EmailNotification,
    comment_mention: {
      title: 'Comments that @Mention me',
      switchLabel: 'App Notifications',
      default: true
    } as EmailNotification,
    comment_created: {
      title: 'All Comments on any item',
      switchLabel: 'App Notifications',
      default: false
    } as EmailNotification
  },
  historic: {
    disconnects: {
      title: 'Show disconnects for the last...',
      options: [
        { name: '6 months', value: '6' },
        { name: '12 months', value: '12' },
        { name: '24 months', value: '24' },
        { name: '36 months', value: '36' },
        { name: 'All Time', value: 'All' },
        { name: 'None', value: 'None' }
      ] as HistoricOption[]
    },
    cancellations: {
      title: 'Show cancellations for the last...',
      options: [
        { name: 'All Time', value: 'All' },
        { name: 'None', value: 'None' }
      ] as HistoricOption[]
    }
  }
};

const switchSx = {
  '.Mui-checked': {
    color: '#2E01A4 !important'
  },
  '.MuiSwitch-track': {
    backgroundColor: '#878EBE !important'
  }
};

const MySettingsTab: React.FC = () => {
  const [settings, setSettings] = useState<UserPreferences>(getDefaultPreferences());
  const [savingSettings, setSavingSettings] = useState<boolean>(false);
  const { fetchInventory } = useInventory();
  const { setSnack } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    const settingsSub = getUserPreferencesSubject().subscribe((preferences) => setSettings(preferences));

    return () => {
      if (settingsSub) settingsSub.unsubscribe();
    };
  }, []);

  const handleSave = async () => {
    setSavingSettings(true);
    const { error } = await updateUserSettings(settings, 'User Setting');
    setSavingSettings(false);
    if (error) {
      setSnack({
        type: 'error',
        message: 'Failed to save settings.',
        open: true
      });
    } else {
      fetchInventory(true);
      setSnack({
        type: 'success',
        message: 'Settings saved.',
        open: true
      });
    }
  };

  const handleSettingChange = (id: string, section: PrefKey, event?: SelectChangeEvent) => {
    const settingsUpdate = { ...settings };

    if (section === 'notifications') {
      const updatedSection = settingsUpdate?.content?.notifications;
      if (!updatedSection) return;
      let updated = updatedSection.find((s) => s.id === id);
      if (!updated) {
        const defaultItem = id.includes('email')
          ? defaultFormMeta.email[id as keyof typeof defaultFormMeta.email]
          : defaultFormMeta.app[id as keyof typeof defaultFormMeta.app];

        updated = { id, index: updatedSection.length, enabled: !defaultItem.default };
        updatedSection.push(updated);
      } else {
        updated.enabled = !updated.enabled;
      }
    } else {
      const updatedSection = settingsUpdate?.content?.historic;
      if (!updatedSection) return;
      const updated = updatedSection.find((s) => s.id === id);
      if (!updated) return;
      updated.value = event?.target?.value;
    }

    setSettings(settingsUpdate);
  };

  const renderNotifications = () => {
    return Object.keys(defaultFormMeta.app).map((notif) => {
      const appNotification = settings?.content.notifications.find((i) => i.id === notif);
      const defaultApp = defaultFormMeta.app[notif as keyof typeof defaultFormMeta.app];
      const emailNotificaiton = settings?.content.notifications.find((i) => i.id === `${notif}_email`);
      const defaultEmail = defaultFormMeta.email[`${notif}_email` as keyof typeof defaultFormMeta.email];

      return (
        <tr key={`${notif}-setting`}>
          <td className="pl-2">
            <Text
              color="grey9"
              weight="normal"
              size="sm">
              {defaultFormMeta.app[notif as keyof typeof defaultFormMeta.app].title}
            </Text>
          </td>
          <td>
            <div className="flex !ml-auto items-center space-x-3">
              <div>
                <Switch
                  sx={switchSx}
                  size="small"
                  checked={appNotification ? appNotification.enabled : defaultApp.default}
                  onChange={() => handleSettingChange(notif, 'notifications')}
                />
              </div>
            </div>
          </td>
          <td>
            <div className="flex !ml-auto items-center space-x-3">
              <div>
                <Switch
                  sx={switchSx}
                  size="small"
                  checked={emailNotificaiton ? emailNotificaiton.enabled : defaultEmail.default}
                  onChange={() => handleSettingChange(`${notif}_email`, 'notifications')}
                />
              </div>
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <div>
      <table className="mt-4 w-full [&_tr]:border-y">
        <thead>
          <tr className="border-none text-left [&>*]:font-normal [&>*]:p-1">
            <th>
              <Text
                className="font-semibold w-full"
                color="grey9"
                size="sm">
                Notifications
              </Text>
            </th>
            <th>
              <Text
                color="grey9"
                weight="medium"
                size="sm">
                In-App
              </Text>
            </th>
            <th>
              <Text
                color="grey9"
                weight="medium"
                size="sm">
                Email
              </Text>
            </th>
          </tr>
        </thead>
        <tbody>{renderNotifications()}</tbody>
      </table>

      <div className="flex mt-4 px-1">
        <Text
          className="font-semibold w-full"
          color="grey9"
          size="sm">
          Historic Data
        </Text>
      </div>
      <hr className="my-2 h-0.5 border-t-1" />
      {settings?.content.historic.map((item) => (
        <div
          key={item.id}
          className="flex items-center space-x-4 px-1 pb-2">
          <Text
            color="grey9"
            size="sm">
            {defaultFormMeta.historic[item.id as keyof typeof defaultFormMeta.historic].title}
          </Text>
          <div className="flex !ml-auto items-center space-x-3">
            <div>
              <Select
                onChange={(event) => handleSettingChange(item.id, 'historic', event)}
                sx={{ fontSize: '.875rem', height: '1.5rem' }}
                value={item.value}>
                {defaultFormMeta.historic[item.id as keyof typeof defaultFormMeta.historic].options.map((option) => (
                  <MenuItem
                    key={option.name}
                    value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </div>
      ))}

      <hr className="mt-3 h-0.5 border-t-2" />

      <div className="justify-between mt-5">
        <button
          onClick={() => navigate('/')}
          className={DEFAULT_CANCEL_BUTTON_STYLE}>
          Back
        </button>
        <button
          disabled={savingSettings}
          onClick={handleSave}
          className={`${DEFAULT_ADVANCE_BUTTON_STYLE} float-right`}>
          Save
        </button>
      </div>
    </div>
  );
};

export default MySettingsTab;
