import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from '@mui/x-data-grid-pro';
import { fileNameHelper } from '../../../utils/fileNameHelper';

export interface CustomToolbarProps {
  showSearch: boolean;
  title: string;
  setFilterButtonEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
}

export const CustomToolbar = ({ showSearch, title, setFilterButtonEl }: CustomToolbarProps) => {
  return (
    <GridToolbarContainer
      data-testid="grid-toolbar"
      sx={{ padding: '1rem 0rem 2rem 1rem' }}>
      {showSearch && (
        <GridToolbarQuickFilter
          data-testid="grid-toolbar-search"
          data-cy="data-grid-search"
        />
      )}
      <div className={`${showSearch ? 'ml-auto' : ''}`}>
        <GridToolbarColumnsButton
          slotProps={{
            button: {
              color: 'secondary'
            }
          }}
        />
        <GridToolbarFilterButton
          slotProps={{
            button: {
              color: 'secondary'
            }
          }}
          ref={setFilterButtonEl}
        />
        <GridToolbarDensitySelector
          slotProps={{
            button: {
              color: 'secondary'
            }
          }}
        />
        <GridToolbarExport
          csvOptions={{
            fileName: `${fileNameHelper(title)}`
          }}
          slotProps={{
            button: {
              color: 'secondary'
            }
          }}
        />
      </div>
    </GridToolbarContainer>
  );
};
