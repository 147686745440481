import createActionCableConsumer from './actionCableConsumer';

interface NotificationChannelParams {
  connectedHandler?: () => void;
  disconnectedHandler?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  receivedHandler?: (data: any) => void;
}

const notificationChannel = ({ connectedHandler, disconnectedHandler, receivedHandler }: NotificationChannelParams) => {
  const actionCableCredentials = JSON.parse(localStorage.getItem('action-cable-credentials') || '');

  if (!actionCableCredentials) {
    console.error('Unable to start notification channel, invalid credentials');
    return;
  }

  const { uid, accessToken, client } = actionCableCredentials;
  const consumer = createActionCableConsumer(uid, accessToken, client);

  return consumer.subscriptions.create(
    {
      channel: 'NotificationChannel'
    },
    {
      connected: connectedHandler,
      disconnected: disconnectedHandler,
      received: receivedHandler
    }
  );
};

export default notificationChannel;
